import React, { useState,useEffect } from 'react';
import Sidebar from './Sidebar';
import Navbar from "./Navbar";
import RightSidebar from './RightSidebar';
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { Profileget } from "../apis/api/ProfileApi";
// import MainSidebar from './MainSidebar';

const MainLayout = ({ children }) => {


   return (
    <>
    
{/*    
      // <Navbar /> */}
  <div className="container-fluid">
  <div className="row row1 d-flex flex-lg-row flex-md-column flex-sm-column flex-column">
 
    <div className="col-lg-2 col-md-12 col-sm-12 col-12 d-flex justify-content-start">
      <Sidebar />
    </div>

  
    <div className="col-lg-6 col-md-12 col-sm-12 col-12 margin-top MainComponent">
      {children}
    </div>

   
    <div className="col-lg-3 col-md-12 col-sm-12 col-12 margin-top ps-4 Rightshow">
      <RightSidebar />
    </div>
  </div>
</div>


          </>
  );
};

export default MainLayout;
