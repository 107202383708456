import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import GenericModal from "../hooks/GenericModel";
import { FILE_URL } from "../apis/api/Config";
import CompanyRegistration from"../forms/CompanyRegistraion"
import { getMemberDetailsByCompanyId } from "../apis/api/ProfileApi";

const TeamMember = ({ member }) => {
  const { member_name, member_job_title, company_company_logo } = member;
  const fallbackImage2="https://www.ihna.edu.au/blog/wp-content/uploads/2022/10/user-dummy.png";
  const handleError1 = (e) => {
    e.target.src = fallbackImage2; 
  };


  return (
    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
      <div className="our-team mt-5">
        <div className="picture">
          <img className="img-fluid" src={`${FILE_URL}/${member.member_member_logo}`} onError={handleError1}  />
        </div>
        <div className="team-content">
          <h6 className="name">{member_name}</h6>
          <p className="title">{member_job_title}</p>
        </div>
        <ul className="social">
          {/* <li><a href="" className="fa fa-facebook" aria-hidden="true"></a></li>
          <li><a href="" className="fa fa-twitter" aria-hidden="true"></a></li>
          <li><a href="" className="fa fa-google-plus" aria-hidden="true"></a></li>
          <li><a href="" className="fa fa-linkedin" aria-hidden="true"></a></li> */}
        </ul>
      </div>
    </div>
  );
};


const Team = () => {
   
  const dispatch = useDispatch();
  const [teamMembers, setTeamMembers] = useState([]);
  const [showModal, setShowModal] = useState(false);
 

  useEffect(() => {
    const fetchData = async () => {
      try {
      
        const response = await dispatch(getMemberDetailsByCompanyId());
      
        const data = response.payload.data;
        setTeamMembers(data);
      } catch (error) {
        console.error("Error fetching team members:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <div className="mt-4">
        <button className="btn new-teammates-btn bt-sm ms-3 bg-theme" onClick={handleOpenModal}>
          <i className="fas fa-plus"></i> New Teammates
        </button>

        <div className="container">
          <div className="row">
            {teamMembers.map((member) => (
              <TeamMember key={member.member_id} member={member} />
            ))}
          </div>
        </div>
      </div>

      <GenericModal show={showModal} handleClose={handleCloseModal}>
        <CompanyRegistration handleCloseModal={handleCloseModal} />
      </GenericModal>
    </>
  );
};

export default Team;
