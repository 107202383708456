import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { statelist, countrylist } from "../apis/api/DataApi";
import { updateMemberDetails } from "../apis/api/ProfileApi";
import Cookies from "js-cookie";
import Select from "react-select";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const AboutDetails = (props) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const userId = Cookies.get("userId");

  useEffect(() => {
    dispatch(countrylist());
    dispatch(statelist());
  }, [dispatch]);

  const { country_list, state_list } = useSelector((state) => state.dataSlice);
  const countryOptions = country_list?.data || [];
  const stateOptions = state_list?.data || [];
  console.log(stateOptions)

  const onSubmitHandler = async (formData) => {
    const data = new FormData();
    const company_id = Cookies.get("company_id");

    Object.keys(formData).forEach((key) => {
      if (typeof formData[key] === "object" && formData[key] !== null) {
        Object.keys(formData[key]).forEach((subKey) => {
          data.append(`${key}[${subKey}]`, formData[key][subKey]);
        });
      } else {
        data.append(key, formData[key]);
      }
    });

    data.append("userId", userId);
    data.append("company_id", company_id);

    const response = await dispatch(updateMemberDetails(data));

    if (response?.payload?.status === "success") {
      toast.success("About Details Updated Successfully!");
    } else {
      toast.error("Failed to Update About Details.");
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <span className="text-bold">About Details</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mx-1">
            <form method="post" onSubmit={handleSubmit(onSubmitHandler)}>
              <div className="form-container">
                <div className="d-block border rounded my-2 px-2 py-0">
                  <div className="position-relative text-left">
                    <span className="d-inline bg-primary text-white border rounded px-2 py-1 button-theme">
                      <span className="text-bold">About Details</span>
                    </span>
                    <div className="row">
                      <div className="row mt-2">
                        {/* Country Name */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">
                              Country Name{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Select
                              options={countryOptions}
                              onChange={(selectedOption) =>
                                setValue("CountryName", selectedOption.value)
                              }
                            />
                            {errors.CountryName && (
                              <span className="text-danger">
                                {errors.CountryName.message}
                              </span>
                            )}
                          </div>
                        </div>

                        {/* State Name */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">
                              State Name <span className="text-danger">*</span>
                            </label>
                            <Select
                              options={
                                Array.isArray(stateOptions) ? stateOptions : []
                              }
                              onChange={(selectedOption) =>
                                setValue("StateName", selectedOption.value)
                              }
                            />
                            {errors.StateName && (
                              <span className="text-danger">
                                {errors.StateName.message}
                              </span>
                            )}
                          </div>
                        </div>

                        {/* City Name */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">
                              City Name <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control border border-grey border-1 rounded-1"
                              placeholder="City Name"
                              {...register("CityName", {
                                required: "City Name is required",
                              })}
                            />
                            {errors.CityName && (
                              <span className="text-danger">
                                {errors.CityName.message}
                              </span>
                            )}
                          </div>
                        </div>

                        {/* Address */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">
                              Address <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control border border-grey border-1 rounded-1"
                              placeholder="Address"
                              {...register("Address", {
                                required: "Address is required",
                              })}
                            />
                            {errors.Address && (
                              <span className="text-danger">
                                {errors.Address.message}
                              </span>
                            )}
                          </div>
                        </div>

                        {/* Contact Number */}
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label className="form-label">
                              Contact Number{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="d-flex">
                              <Select
                                options={countryOptions}
                                onChange={(selectedOption) =>
                                  setValue("country_list", selectedOption.value)
                                }
                                className="country-code-select h-1rem"
                              />
                              <input
                                type="text"
                                className="form-control border border-grey border-1 rounded-1"
                                placeholder="Contact"
                                {...register("Mobile", {
                                  required: "Contact No is required",
                                })}
                              />
                            </div>
                            {errors.country_list && (
                              <span className="text-danger">
                                {errors.country_list.message}
                              </span>
                            )}
                            {errors.Mobile && (
                              <span className="text-danger">
                                {errors.Mobile.message}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-2">
                <button type="submit" className="btn button-theme">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AboutDetails;
