import React from "react";

const GenericModal = ({ show, handleClose, title, children, postImage }) => {
  if (!show) return null;

  return (
    <div className="modal modalll " style={{ display: 'block' }}>
      <div className="modal-dialog modal-lg modalll-dialog ">
        <div className="modal-content modalll-content ">
      
          <div className="modal-body row modalll-body" style={{ overflowY: 'auto',}}>
          <div className="modal-header modalll-header mtN-28">
            <h5 className="modal-title modalll-title">{title}</h5>
            <button
              type="button"
              className="close btn btn-danger btn-sm"
              onClick={handleClose}
            >
              <span>&times;</span>
            </button>
          </div>
            {postImage && (
              <div className="col-lg-6 col-12 d-flex align-items-center justify-content-center mb-3">
                <img 
                  src={postImage} 
                  alt="Post" 
                  className="img-fluid" 
                  style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} 
                />
              </div>
            )}
            <div className={postImage ? "col-lg-6 col-12" : "col-12"}>
              {children}
            </div>
          </div>
          <div className="modal-footer modalll-footer">
            {/* You can add buttons or other footer content here */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenericModal;
