import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FILE_URL } from "../apis/api/Config";
import { NewsApi } from "../apis/api/NewsApi";
import { fetchUsers } from "../apis/api/ProfileApi";
import Loading from "../hooks/Loading";
import {latestMember,activeMember,Magazine,NewsAll, profileAll,} from "../apis/api/DashboardApi";
import { useNavigate, useLocation, Link } from "react-router-dom";

const MemberCard = ({
  title,
  country,
  countryImage,
  borderClass,
  textClass,
}) => (
  <div className="col-xl-3 col-md-6 mb-4">
  <div
    className={`card ${borderClass} shadow h-100 py-2 custom-hover-card`}
    style={{ width: "100%" }}
  >
    <div className="card-body">
      <div className="row no-gutters align-items-center">
        <div className="col mr-2">
          <div
            className={`text-xs font-weight-bold ${textClass} text-uppercase mb-1`}
          >
            {title}
          </div>
          <div className="d-flex align-items-center">
            <img
              src={`${FILE_URL}${countryImage}`}
              alt="Logo"
              className="img-fluid mr-2"
              style={{ width: "30px", height: "30px", borderRadius: "45%" }}
            />
            <div className="ps-2">{country}</div>
          </div>
        </div>
        <div className="col-auto"></div>
      </div>
    </div>
  </div>
</div>

);

const Dashboard = () => {
  const dispatch = useDispatch(NewsApi);
  const navigate = useNavigate();
  const location = useLocation();
  
  


  useEffect(() => {
    fetchData();
  }, []);

 
  async function fetchData() {
    await dispatch(fetchUsers()).catch((error) => {
      console.error("Failed to fetch profiles.");
    });
    await dispatch(NewsApi()).catch((error) => {
      console.error("Failed to fetch profiles.");
    });
    await dispatch(activeMember()).catch((error) => {
      console.error("Failed to fetch profiles.");
    });
    await dispatch(latestMember()).catch((error) => {
      console.error("Failed to fetch profiles.");
    });
    await dispatch(Magazine()).catch((error) => {
      console.error("Failed to fetch profiles.");
    });

    await dispatch(
      NewsAll({
        filter: "6",
      })
    ).catch((error) => {
      console.error("Failed to fetch profiles.");
    });

    await dispatch(profileAll()).catch((error) => {
      console.error("Failed to fetch profiles.");
    });

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }

  let { activemember } = useSelector((state) => state.dashboardSlice);
  activemember = activemember?.result || [];
  activemember = activemember.slice(0, 8);
  let { profile } = useSelector((state) => state.dashboardSlice);

  

  let { latestmember } = useSelector((state) => state.dashboardSlice);
  latestmember = latestmember?.result || [];
  latestmember = latestmember.slice(0, 8);

  let { magazine } = useSelector((state) => state.dashboardSlice);
  magazine = magazine?.data || [];
  magazine = magazine.slice(0, 8);

  let { news } = useSelector((state) => state.dashboardSlice);
  let newsData = news?.data || [];

  const handleRedirect = (news) => {
    navigate("/news", { state: { newsData: news } });
  };

  const [Query, setQuery] = useState("");
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isInputEmpty, setInputEmpty] = useState(true);
  const searchBoxRef = useRef(null);

  const handleInputChange = (event) => {
    const value = event.target.value || "";
    setQuery(value);
    setInputEmpty(value === "");
    setDropdownVisible(value !== "");
  };

  const handleClickOutside = (event) => {
    if (searchBoxRef.current && !searchBoxRef.current.contains(event.target)) {
      setQuery("");
      setDropdownVisible(false);
    }
  };
  useEffect(() => {
    if (searchBoxRef.current) {
      searchBoxRef.current.value = "";
    }
    setQuery("");
    setDropdownVisible(false);
  }, [location.pathname]);

  const handleImageError = (e) => {
    e.target.src =
      "https://www.ihna.edu.au/blog/wp-content/uploads/2022/10/user-dummy.png";
  };
  const filteredProfiles = profile?.data?.filter(profile => {
    const companyName = profile.company_name || ""; 
    return companyName.includes(Query);  
  });
  

  const loading =
    !magazine.length ||
    !newsData.length ||
    !latestmember.length ||
    !activemember.length;

  return (
    <>
      <Loading loading={loading} />
      <div className="container-fluid mt-5">
        {" "}
        <div className="d-sm-flex align-items-center justify-content-center mb-4">
          <h2>
            <span
              className="fade-in fade-in-1"
              style={{ color: "#rgb(0 32 59)" }}
            >
              Welcome
            </span>
            <span
              className="fade-in fade-in-2"
              style={{ color: "#rgb(0 32 59)" }}
            >
              To
            </span>
            <span
              className="fade-in fade-in-3 ms-1"
              style={{ color: "#6CAEE5", fontWeight: "bold" }}
            >
              FREIGHT
            </span>
            <span
              className="fade-in fade-in-4 ms-1"
              style={{ color: "#6CAEE5", fontWeight: "bold" }}
            >
              LOUNGE
            </span>
          </h2>
        </div>
        <form className="row domain-search bg-pblue d-flex flex-wrap justify-content-around">
          <div className="input-group ps-4 pe-4 w-50" ref={searchBoxRef}>
            <input
              type="search"
              placeholder="Search Members Name"
              className="form-control me-2"
              onChange={handleInputChange}
              value={Query}
            />
            <span className="input-group-addon">
              <input
                type="submit"
                value="Search"
                className="btn btn-primary bg-theme"
              />
            </span>

            {isDropdownVisible && (
              <div
                className="searchbox w-85"
                style={{
                  maxHeight: "37vh",
                  overflowY: "auto",
                  overflowX: "hidden",
                  backgroundColor: "white",
                  border: "1px solid #ccc",
                }}
              >
                {profile.data.length > 0 ? (
                 filteredProfiles.map((profile, index) => (
                    <Link
                      to={`/member/${profile.id}`}
                      className="dropdown-item searchNames d-flex ms-2 mt-3"
                      key={index}
                    >
                      <img
                        src={`${FILE_URL}/${profile.company_logo}`}
                        alt={profile.company_name}
                        className="dropdown-item-image"
                        onError={handleImageError}
                      />
                      <div className="dropdown-item-content">
                        <div className="dropdown-item-name">
                          {profile.company_name}
                        </div>
                        <div className="dropdown-item-company">
                          {profile.address}, {profile.city}, {profile.state}
                        </div>
                        {/* <div className="dropdown-item-membership">
          {profile.member_plan.split(':')[1]} 
        </div> */}
                      </div>
                    </Link>
                  ))
                ) : (
                  <p className="dropdown-item">No members found.</p>
                )}
              </div>
            )}
          </div>
        </form>
        {magazine.length > 0 && (
          <>
                      <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <p className="h5 mb-0 text-gray-80 ps-4 mt-3 text-theme">
                Magazine{" "}
                <i className="fa fa-info-circle" aria-hidden="true"></i>
              </p>
            </div>
          <div className="container-fluid">

            <div className="row d-flex flex-row">
              {magazine.map((mag, index) => (
                <div
                  className="col-12 col-sm-6 col-md-3
                                                 mb-4"
                >
                  <div className="magzin-card">
                    <a
                      href={mag.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="custom-card-image">
                        <img
                          src={`${FILE_URL}${mag.magazine_photo}`.replace(
                            "/thumb/",
                            "/"
                          )}
                          alt="Magazine"
                          className=""
                        />
                      </div>
                    </a>
                  </div>
                </div>
              ))}
            </div>
            </div>
          </>
        )}
        {newsData.length > 0 && (
          <>
            <div className="d-flex align-items-center justify-content-between mb-4">
              <p className="h5 mb-0 text-gray-80 ps-4 mt-3 text-theme">
                News Section{" "}
                <i className="fa fa-info-circle" aria-hidden="true"></i>
              </p>
              <a href="" className="me-4">
                Show all
              </a>
            </div>
            <div className="container-fluid">
              <div className="row">
                {newsData.map((news, index) => (
                  <div
                    className={`col-12 col-sm-6 col-md-3 mb-4 `}
                    key={`${news.id}-${index}`}
                  >
                    <div className="custom-card">
                      <div
                        className="custom-card-image d-flex justify-content-around "
                        style={{
                          backgroundImage: `url(${`${FILE_URL}${news.image}`.replace(
                            "/thumb/",
                            "/"
                          )})`,
                        }}
                      >
                        <div className="custom-card-content">
                          {/* <h4 className="custom-card-title news-tittle">{news.description}</h4> */}
                          <a
                            className="custom-card-button"
                            onClick={() => handleRedirect(news)}
                          >
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
        {latestmember.length > 0 && (
          <>
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <p className="h5 mb-0 text-gray-80 ps-4 mt-3 text-theme">
                Most Recent Members{" "}
                <i className="fa fa-info-circle" aria-hidden="true"></i>
              </p>
            </div>
            <div className="row ps-2 pe-2">
              {latestmember.map((member, index) => (
                <MemberCard
                  key={index}
                  title={member.company_name}
                  country={member.member_name}
                  countryImage={member.member_logo}
                  borderClass={member.borderClass}
                  textClass={member.textClass}
                />
              ))}
            </div>
          </>
        )}
        {activemember.length > 0 && (
          <>
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <p className="h5 mb-0 text-gray-80 ps-4 mt-3 text-theme">
                Active Members{" "}
                <i className="fa fa-info-circle" aria-hidden="true"></i>
              </p>
            </div>
            <div className="row ps-2 pe-2 ">
              {activemember.map((member, index) => (
                <MemberCard
                  key={index}
                  title={member.company_name}
                  country={member.member_name}
                  countryImage={member.member_logo}
                  borderClass={member.borderClass}
                  textClass={member.textClass}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Dashboard;
