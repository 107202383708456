import React, { useState } from 'react';
import 'boxicons/css/boxicons.min.css';
import Main from '../feeds/Main2';

import RightSidebar from './RightSidebar';
import Navbar from './Navbar';


const MainSidebar = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [activeLink, setActiveLink] = useState('dashboard');

  const handleToggleClick = () => {
    setShowSidebar(!showSidebar);
  };

  const handleLinkClick = (linkName) => {
    setActiveLink(linkName);
  };

  return (
    <>
    <Navbar/>
    <div id="body-pd" className={showSidebar ? 'body-pd' : ''}>
      <div className={`l-navbar ${showSidebar ? 'show' : ''}`} id="nav-bar">
        <nav className="nav">
          <div className="nav_list">
            
            <a
              href="#"
              className={`nav_link ${showSidebar ? 'bx bx-layer nav_logo-icon-x-x' : 'bx-menu'}`}
              onClick={handleToggleClick}
            >
              <i className={`bx ${showSidebar ? '' : 'bx-menu'} nav_icon`}></i>
              <span className="nav_name"></span>
            </a>
            <a
              href="#"
              className={`nav_link ${activeLink === 'dashboard' ? 'active' : ''}`}
              onClick={() => handleLinkClick('dashboard')}
            >
              <i className='bx bx-grid-alt nav_icon'></i>
              <span className="nav_name">Dashboard</span>
            </a>
            <a
              href="#"
              className={`nav_link ${activeLink === 'users' ? 'active' : ''}`}
              onClick={() => handleLinkClick('users')}
            >
              <i className='bx bx-user nav_icon'></i>
              <span className="nav_name">Users</span>
            </a>
            <a
              href="#"
              className={`nav_link ${activeLink === 'messages' ? 'active' : ''}`}
              onClick={() => handleLinkClick('messages')}
            >
              <i className='bx bx-message-square-detail nav_icon'></i>
              <span className="nav_name">Messages</span>
            </a>
            <a
              href="#"
              className={`nav_link ${activeLink === 'bookmark' ? 'active' : ''}`}
              onClick={() => handleLinkClick('bookmark')}
            >
              <i className='bx bx-bookmark nav_icon'></i>
              <span className="nav_name">Bookmark</span>
            </a>
            <a
              href="#"
              className={`nav_link ${activeLink === 'files' ? 'active' : ''}`}
              onClick={() => handleLinkClick('files')}
            >
              <i className='bx bx-folder nav_icon'></i>
              <span className="nav_name">Files</span>
            </a>
            <a
              href="#"
              className={`nav_link ${activeLink === 'stats' ? 'active' : ''}`}
              onClick={() => handleLinkClick('stats')}
            >
              <i className='bx bx-bar-chart-alt-2 nav_icon'></i>
              <span className="nav_name">Stats</span>
            </a>
            <a
              href="#"
              className={`nav_link ${activeLink === 'signout' ? 'active' : ''}`}
              onClick={() => handleLinkClick('signout')}
            >
              <i className='bx bx-log-out nav_icon'></i>
              <span className="nav_name">SignOut</span>
            </a>
          </div>
        </nav>
      </div>

      <div className="height-100 bg-light main-content">
      <div className="row">
            
            <div className="col-md-6 margin-top MainComponent ">
           <Main/>
            </div>
            <div className="col-md-3 margin-top  ps-4">
              <RightSidebar />
            </div>
          </div>
      </div>
    </div>
    </>
  );
};

export default MainSidebar;
