

import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import Modal from 'react-bootstrap/Modal';
import FileInput from "../../hooks/FileInput";

const UploadAlbum = (props) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  const userId = Cookies.get("userId");

 

 

  const handleFileChange = ({ target: { name, value } }) => {
    setValue(name, value);
  };

 

  return (
    <>
      <Modal {...props} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Album upload</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form method="post" >
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label">Album Tilte</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    {...register("Name", { required: "Album Tilte is required" })}
                  />
                  {errors.Name && (
                    <span className="text-danger">{errors.Name.message}</span>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <FileInput
                  name="Member_logo"
                  label="Album photo"
                  handleChange={handleFileChange}
                  {...register("Member_logo")}
                />
              </div>
            </div>

            <div className="mt-3">
              <button type="submit" className="btn btn-primary bg-theme">
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UploadAlbum;
