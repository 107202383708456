
import GenericModal from "../hooks/GenericModel";
import React, { useState, useRef, useEffect } from "react";
import CommentArea from "../../src/feeds/Comment";
import { FeedsInsert, FeedsGet, FeedsLikes } from "../apis/api/FeedsApi";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { FILE_URL } from "../apis/api/Config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../hooks/Loading";
import Lodder from "../hooks/Lodder";
import Tags from "../feeds/tags";
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css'; 
import Select from "react-select";
import { Profilelist } from "../apis/api/ProfileApi";

const Timelines= () => {
  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState(false);
  const [company_id, setCompany] = useState("");
  const [filter, setfilter]=useState("");
  const [showTags, setShowTags] = useState(false);
  const [postImage, setPostImage] = useState("");


  const [date, setdate] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection'
    }
  ]);

  const company_filter = (selectedOption) => {
    setCompany(selectedOption.value);
  };

 

  const handleFilterToggle = () => {
    setShowFilter(!showFilter);
  };

  const {  loading  } = useSelector((state) => state.feedsSlice);

  

  const [feedsData, setFeedsData] = useState([]);
  const [page, setPage] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);
  const [lastId, setLastId] = useState(null);
  const [fullfill, setfullfill] = useState(0);
  const [list, setlist] = useState(null);

  const [previews, setPreviews] = useState({
    musicPreview: null,
    imagePreview: null,
    videoPreview: null,
  });
  const memberId = Cookies.get("userId");
  

  useEffect(() => {
    fetchFeeds(false);
    fetchprofilelist();
  }, []);

  const fetchprofilelist= async ()=>{
    const pofilelist= await dispatch(Profilelist({filter:1}))
    if (pofilelist.payload.status == 'success') {
      let data=pofilelist.payload.data;
      setlist(data);
    }
  }

  
  const fetchFeeds = async (filter,filterpage) => {
    setLoadingMore(true);
    let response;

    if (fullfill == 0) {
      if (page === 0) {
        if(!filter){
          response = await dispatch(
            FeedsGet({ member: memberId, lastid: 0, pagination: 0 })
          );
        }else{
          var filterdate=[{
             'Start_Date':date[0].startDate.toDateString(),
             'End_Date' :date[0].endDate ? date[0].endDate.toDateString() : 0
          }]

          response = await dispatch(
            FeedsGet({ member: memberId, lastid: 0, pagination: 0 ,filter:filter,company_id: company_id,filterdate:filterdate})
          );
        }
        
      } else {
        if(!filter){
          response = await dispatch(FeedsGet({member: memberId,lastid: lastId,pagination: page,}));
        }else if(filterpage){

          var filterdate=[{
            'Start_Date':date[0].startDate.toDateString(),
            'End_Date' :date[0].endDate ? date[0].endDate.toDateString() : 0
         }];

          response = await dispatch(FeedsGet({member: memberId,lastid: lastId,pagination: 0,filter:filter,company_id: company_id,filterdate:filterdate}));
        }else{

          var filterdate=[{
            'Start_Date':date[0].startDate.toDateString(),
            'End_Date' :date[0].endDate ? date[0].endDate.toDateString() : 0
         }]

          response = await dispatch(FeedsGet({member: memberId,lastid: lastId,pagination: page,filter:filter,company_id: company_id,filterdate:filterdate}));
        }
       
      }
      if (response.payload.data == 0) {
        setfullfill(1);
      }
      if (response.payload.data) {
        if (response.payload.data.length > 0) {
          if (page === 0) {
            setFeedsData(response.payload.data);
          }else if(filterpage){
            setFeedsData(response.payload.data);
          }else {
            setFeedsData((prevFeeds) => [
              ...prevFeeds,
              ...response.payload.data,
            ]);
          }

          if (
            lastId !==
            response.payload.data[response.payload.data.length - 1]
              .company_post_id
          ) {
            setLastId(
              response.payload.data[response.payload.data.length - 1]
                .company_post_id
            );
            if(filterpage){
              setPage((prevPage) => 0 + 1);
            }else{
              setPage((prevPage) => prevPage + 1);
            }
            
          }
        }
      }
    }
    setLoadingMore(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
          document.documentElement.scrollHeight &&
        !loadingMore
      ) {
        fetchFeeds(filter);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loadingMore]);





  const [showModal, setShowModal] = useState(false);
  const [post_id, setpost_id] = useState(false);
  // const comments = [<CommentArea />];

  const handleShow = (id, imageUrl) => {
    setPostImage(imageUrl);
    setShowModal(true);
    setpost_id(id);
  };
  const handleClose = () => setShowModal(false);

  const [formData, setFormData] = useState({
    textarea: "",
    musicFile: null,
    imageFile: null,
    videoFile: null,
    tags: [],
  });

  const [selectedFileType, setSelectedFileType] = useState(null);

  const musicInputRef = useRef(null);
  const imageInputRef = useRef(null);
  const videoInputRef = useRef(null);
  const likeCountsRef = useRef({});

  const handleIconClick = (inputRef, fileType) => {
    setSelectedFileType(fileType);
    inputRef.current.click();
    setShowTags(true);
  };

  const handleFileChange = (event, fileType) => {
    const file = event.target.files[0];
    if (!file) {
      setSelectedFileType(null);
      setShowTags(false);
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviews((prevState) => ({
        ...prevState,
        [`${fileType.toLowerCase()}Preview`]: reader.result,
      }));
    };
    reader.readAsDataURL(file);

    const validTypes = {
      Music: ["audio/mpeg", "audio/wav", "audio/mp3"],
      Image: ["image/jpeg", "image/png", "image/gif"],
      Video: ["video/mp4", "video/avi", "video/mov"],
    };

    if (validTypes[fileType].includes(file.type)) {
      const fileURL = URL.createObjectURL(file);
      setFormData((prevData) => ({
        ...prevData,
        [`${fileType.toLowerCase()}File`]: file,
      }));

      setPreviews((prevPreviews) => ({
        ...prevPreviews,
        [`${fileType.toLowerCase()}Preview`]: fileURL,
      }));
    } else {
      toast.error(`Please select a valid ${fileType.toLowerCase()} file.`);
    }
  };

  const handleTextareaChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      textarea: event.target.value,
    }));
    setShowTags(event.target.value !== "");
  };

  const handleRemoveFile = (fileType, inputRef) => {
    setFormData((prevData) => ({
      ...prevData,
      [`${fileType.toLowerCase()}File`]: null,
    }));

    setPreviews((prevPreviews) => ({
      ...prevPreviews,
      [`${fileType.toLowerCase()}Preview`]: null,
    }));

    if (inputRef.current) {
      inputRef.current.value = "";
    }
    setSelectedFileType(null);
    setShowTags(false);
  };
  // const [status, setStatus] = useState("idle");

  const handlefilter = async (event) =>{
    event.preventDefault();
    // setfullfill(0);
    var filterpage=1;
    
    setfilter(prevState => {
      const newState = 1; 
      fetchFeeds(newState,filterpage); 
      return newState;
    });
    

  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    let response;
    const company_id = Cookies.get("company_id");
    const sorce_type = Cookies.get("sorce_type");
    const member_detail=JSON.parse(Cookies.get('userJSON'));
    console.log(member_detail);

    const formDataToSend = new FormData();
    formDataToSend.append("textarea", formData.textarea);
    formDataToSend.append("company_id", company_id);
    formDataToSend.append("sorce_type", sorce_type);
    formDataToSend.append("member_id", memberId);
    formDataToSend.append("perweak_post", member_detail.post_per_weak);
    formDataToSend.append("upload_limit", member_detail.feeds_upload);

    if (formData.musicFile)
      formDataToSend.append("musicFile", formData.musicFile);
    if (formData.imageFile)
      formDataToSend.append("imageFile", formData.imageFile);
    if (formData.videoFile)
      formDataToSend.append("videoFile", formData.videoFile);

    if (formData.tags && formData.tags.length > 0) {
      const yearsArray = formData.tags.map((tag) => tag.year);
      formDataToSend.append("tags", JSON.stringify(yearsArray));
    }
    
    if (formData.musicFile || formData.imageFile || formData.videoFile || formData.textarea !== "") {
      response = await dispatch(FeedsInsert(formDataToSend));
      console.log(response)
      if (response.payload.status == "success") {
        handleRemoveFile("Music", musicInputRef);
        handleRemoveFile("Image", musicInputRef);
        handleRemoveFile("Video", musicInputRef);
        setFormData({
          textarea: "",
          tags: [],
        });
        toast.success("post successful!");
        response = await dispatch(
          FeedsGet({ member: memberId, lastid: 0, pagination: 0 })
        );
        if(response.payload.data){
          setFeedsData(response.payload.data);
        }
       
      }
      if (response.payload.status == "error") {
        setFormData({
          textarea: "",
          tags: [],
        });
        toast.error(response.payload.message);
      }

    } else {
      toast.error(`Please select a valid  file.`);
    }
  };

  const [like_set, setlike_set] = useState(null);
  const [post_set, setpost_set] = useState(null);

  const lastlikecount = useRef(null);
  const lastlikestatus = useRef(null);
  const currentlikecount = useRef(null);
  const currentlikestatus = useRef(null);
 

  const handleLikeClick = async (feedId, likesCount, LikedStatus) => {
    const newLikesCount = LikedStatus
      ? parseInt(likesCount) - 1
      : parseInt(likesCount) + 1;

    if (like_set !== likesCount || post_set !== feedId) {
      dispatch(
        FeedsLikes({
          feedId: feedId,
          like: newLikesCount,
          memberId: memberId,
          LikedStatus: LikedStatus,
        })
      );
      const curlikestatus = LikedStatus ? false : true;

      lastlikecount.current = newLikesCount;
      lastlikestatus.current = curlikestatus;
      var insElement = document.getElementById(feedId);

      if (insElement) {
        insElement.innerHTML = newLikesCount;
        const liElement = insElement.closest("li");

        if (liElement) {
          const buttonElement = liElement.querySelector("button");
          if (LikedStatus) {
            buttonElement.classList.remove("liked");
          } else {
            buttonElement.classList.add("liked");
          }
        }
      }
    } else {
      currentlikestatus.current = lastlikestatus.current ? false : true;
      currentlikecount.current = lastlikestatus.current
        ? parseInt(lastlikecount.current) - 1
        : parseInt(lastlikecount.current) + 1;
      dispatch(
        FeedsLikes({
          feedId: feedId,
          like: currentlikecount.current,
          memberId: memberId,
          LikedStatus: currentlikestatus.current,
        })
      );

      var insElement = document.getElementById(feedId);
      if (insElement) {
        insElement.innerHTML = currentlikecount.current;
        const liElement = insElement.closest("li");

        if (liElement) {
          const buttonElement = liElement.querySelector("button");
          if (lastlikestatus.current) {
            buttonElement.classList.remove("liked");
          } else {
            buttonElement.classList.add("liked");
          }
        }
      }
      lastlikecount.current = currentlikecount.current;
      lastlikestatus.current = currentlikestatus.current;
    }

    setlike_set(likesCount);
    setpost_set(feedId);
  };

  const handleReset = (fileType, inputRef) => {
    handleRemoveFile(fileType, inputRef);
    setShowTags(false);
  };

  return (
    <>
      {/* <Loading loading={loading} /> */}
      {/* {loading && <Lodder />} */}
      <div className="">
        <div className="">
          <div class="central-meta">
          
          </div>

         

          <div className="feed-container">
            {feedsData.map((feed) => {
              likeCountsRef.current[feed.company_post_id] =
                feed.company_post_like;
              return (
                <div className="central-meta item" key={feed.company_post_id}>
                  <div className="user-post">
                    <div className="friend-info">
                      <figure>
                        <img
                          className="Image"
                          src={`${FILE_URL}${feed.company_company_logo}`}
                          alt=""
                        />
                      </figure>
                      <div className="friend-name">
                        <ins className="ins d-flex justify-content-between align-items-center">
                          <a href="time-line.html" title="">
                            {feed.member_name} || {feed.company_company_name}
                          </a>
                        </ins>
                        <span>{feed.company_post_created_at}</span>
                      </div>
                      <div className="post-meta">
                        <img
                          className="imgContainer "
                          src={`${FILE_URL}${feed.company_post_image_url}`}
                          alt=""
                        />

                        <div className="we-video-info">
                          <ul>
                            <li>
                              <button
                                type="button"
                                className={`btn btn-Light rounded-circle fa-regular fa-thumbs-up ${
                                  feed.liked ? "liked" : ""
                                }`}
                                onClick={() =>
                                  handleLikeClick(
                                    feed.company_post_id,
                                    feed.company_post_like,
                                    feed.liked
                                  )
                                }
                              ></button>
                              <ins id={feed.company_post_id}>
                                {likeCountsRef.current[feed.company_post_id]}
                              </ins>
                            </li>
                            <li>
                              <button
                                type="button"
                                className="btn btn-Light rounded-circle fa-regular fa-comment "
                                onClick={() => handleShow(feed.company_post_id, `${FILE_URL}${feed.company_post_image_url}`)}
                              ></button>
                              <ins>2.3k</ins>
                            </li>
                          
                          </ul>
                        </div>
                        <div className="description">
                          <p>{feed.company_post_description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            {loadingMore && <Lodder />}
          </div>
        </div>
        <GenericModal
          show={showModal}
          handleClose={handleClose}
          title="Comments"
           postImage={postImage}
        >
          <CommentArea id={post_id} />
        </GenericModal>
      </div>
    </>
  );
};

export default Timelines;
