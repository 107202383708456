import { createSlice } from "@reduxjs/toolkit";
import { membership } from "../api/MembershipApi";


const membershipSlice = createSlice({

    name: "membershipSlice",
    initialState: {
        loading: false,
        membership_detail: '',
    
    },
    reducers:{
      
    },

    extraReducers: (builder) => {
// membership plan
      builder.addCase(membership.pending, state => {
        state.loading = true
      })
      builder.addCase(membership.fulfilled,  (state, action) => {
       state.membership_detail=action.payload;
        state.loading = false
      })
      builder.addCase(membership.rejected, (state,action) => {
        console.log("rejected")
        state.loading = false
      })




    }
  })
  export default membershipSlice.reducer;