import React from "react";
import Select from "react-select";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const BasicServices = (props) => {
  const dropdownOptions1 = [
    { value: "Option 1", label: "Option 1" },
    { value: "Option 2", label: "Option 2" },
    { value: "Option 3", label: "Option 3" },
  ];

  const dropdownOptions2 = [
    { value: "Option A", label: "Option A" },
    { value: "Option B", label: "Option B" },
    { value: "Option C", label: "Option C" },
  ];

  const dropdownOptions3 = [
    { value: "Type X", label: "Type X" },
    { value: "Type Y", label: "Type Y" },
    { value: "Type Z", label: "Type Z" },
  ];

  const onSubmitHandler = (e) => {
    e.preventDefault();
  
    console.log("Form submitted");
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Services
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mx-1">
            <form onSubmit={onSubmitHandler}>
              <div className="form-container">
                <div className="d-block border rounded my-2 px-2 py-0">
                  <div className="row mt-2">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Dropdown 1 <span className="text-danger">*</span>
                        </label>
                        <Select options={dropdownOptions1} />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Dropdown 2 <span className="text-danger">*</span>
                        </label>
                        <Select options={dropdownOptions2} />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Dropdown 3 <span className="text-danger">*</span>
                        </label>
                        <Select options={dropdownOptions3} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-2">
                <button type="submit" className="btn button-theme">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BasicServices;
