
import React from "react";
import "./Thankyou.css";
import { Link } from "react-router-dom";
import { signup_details } from "../apis/slice/AuthSlice";
import { useDispatch } from "react-redux";

const Thankyou = () => {
  const dispatch = useDispatch();
  dispatch(signup_details());

  return (


    <div className="content">
      <div className="wrapper-1">
        <div className="wrapper-2">
          <h1>Thank you !</h1>
          <p>Thanks for Register with us.</p>
          <p>You should receive a confirmation email that contain your Id password .</p>
          <Link to="/"><button className="go-home">Login</button></Link>
        </div>
        <div className="footer-like">
          
        </div>
      </div>
    </div>
  );
};

export default Thankyou;

