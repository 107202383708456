import React, { useState } from "react";
import MemberDetails from "../feeds/memberInformation/MemberDetail";
import GenericModal from "../hooks/GenericModel";
import EditMemberDetails from "../forms/EditDetailsofMember";
import Profile from "../Profile/profile";

const Profile1 = () => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false); 

  const handleOpenEditModal = () => setShowEditModal(true);
  const handleCloseEditModal = () => setShowEditModal(false);

  const handleOpenProfileModal = () => setShowProfileModal(true);
  const handleCloseProfileModal = () => setShowProfileModal(false);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          {/* <div className="col-md-3">
            <div>
              <a href="#" className="card cardxx human-resources">
                <div className="overlay"></div>
                <div className="circle">
                  <img
                    src="https://www.ihna.edu.au/blog/wp-content/uploads/2022/10/user-dummy.png"
                    alt="memberlogo"
                  />
                </div>
                <p>Ankit Pandey</p>
                <div className="LIST">
                  <ul className="list-group mt-3">
                    <li className="list-group-item lihover" onClick={handleOpenEditModal}>
                      <i className="fas fa-user-edit pe-2"></i> Edit Profile
                    </li>
                    <li className="list-group-item lihover">
                      <i className="fas fa-bell pe-2"></i> Notifications
                    </li>
                    <li className="list-group-item lihover" onClick={handleOpenProfileModal}>
                      <i className="fas fa-clipboard-list pe-2"></i> Company Info
                    </li>
                    <li className="list-group-item lihover">
                      <i className="fas fa-lock pe-2"></i> Locations
                    </li>
                    <li className="list-group-item lihover">
                      <i className="fas fa-lock pe-2"></i> Services
                    </li>
                    <li className="list-group-item lihover">
                      <i className="fas fa-lock pe-2"></i> Certification
                    </li>
                    <li className="list-group-item lihover">
                      <i className="fas fa-lock pe-2"></i> Media
                    </li>
                    <li className="list-group-item lihover">
                      <i className="fas fa-lock pe-2"></i> File
                    </li>
                  </ul>
                </div>
              </a>
            </div>
          </div> */}

          <div className="col-md-11">
            <MemberDetails />
          </div>
        </div>
      </div>

    
      <GenericModal show={showEditModal} handleClose={handleCloseEditModal}>
        <EditMemberDetails handleCloseModal={handleCloseEditModal} />
      </GenericModal>

     
      <GenericModal show={showProfileModal} handleClose={handleCloseProfileModal}>
        <Profile handleCloseModal={handleCloseProfileModal} />
      </GenericModal>
    </>
  );
};

export default Profile1;
