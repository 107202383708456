import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL} from "./Config";

export const FeedsInsert = createAsyncThunk(
    "post/FeedsInsert",
    async (data) => { 
        let url = BASE_URL+"feeds/insert";
        try{
            const response = (await axios.post(url, data)).data

            if(response.status == "success" || response.status == "error"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }

        }catch (error) {
            return Promise.reject(error)
        }
    }
)

export const FeedsGet = createAsyncThunk(
    "post/FeedsGet",
    async (data) => { 
        let url = BASE_URL+"feeds/get";
        try{
            
            const response = (await axios.post(url,data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
           // console.log("getMainApps Error:", error)
            return Promise.reject(error)
        }
    }
)

export const FeedsLikes = createAsyncThunk(
    "post/FeedsLikes",
    async (data) => { 
        let url = BASE_URL+"feeds/likes";
        try{
            
            const response = (await axios.post(url,data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
           // console.log("getMainApps Error:", error)
            return Promise.reject(error)
        }
    }
)

export const insertcomment = createAsyncThunk(
    "post/insertcomment",
    async (data) => { 
        let url = BASE_URL+"feeds/insert-comment";
        try{
            
            const response = (await axios.post(url,data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
           // console.log("getMainApps Error:", error)
            return Promise.reject(error)
        }
    }
)

export const fetchcomments = createAsyncThunk(
    "post/fetchcomments",
    async (data) => { 
        let url = BASE_URL+"feeds/get-comment";
        try{
            
            const response = (await axios.post(url,data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
           // console.log("getMainApps Error:", error)
            return Promise.reject(error)
        }
    }
)

export const fetchSearchDetails = createAsyncThunk(
    "profile/list",
    async (data) => { 
        let url = BASE_URL+"profile/list";
        try{
            
            const response = (await axios.post(url,data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
           // console.log("getMainApps Error:", error)
            return Promise.reject(error)
        }
    }
)


