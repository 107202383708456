import { createSlice } from "@reduxjs/toolkit";
import { submitSignUp ,submitLogin ,ResendOtp,VerifyOtp } from "../api/SignupApi";

const authSlice = createSlice({
    name: "authSlice",
    initialState: {
      loading: false,
      signup_detail:'',
      Login_detail: "",
    },
    reducers:{
        signup_details :(state, action)=>{
        state.signup_detail="";
        console.log(action.payload, " authslice",state);
        },

        Login_detail :(state, action)=>{
          state.Login_detail="";
          console.log(action.payload, " authslice",state);
          }

    },

    extraReducers: (builder) => {
      builder.addCase(submitSignUp.pending, state => {
        state.loading = true
      })
      builder.addCase(submitSignUp.fulfilled,  (state, action) => {
       state.signup_detail=action.payload;
        state.loading = false
      })
      builder.addCase(submitSignUp.rejected, (state,action) => {
        console.log("rejected")
        state.loading = false
      })

      builder.addCase(submitLogin.pending, state => {
         state.loading = true
       })
       builder.addCase(submitLogin.fulfilled,  (state, action) => {
        state.Login_detail=action.payload;
        state.loading = false
       })
       builder.addCase(submitLogin.rejected, (state,action) => {
        state.Login_detail=action.error;
         state.loading = false
      
       })

// api for resendotp---------
       builder.addCase(ResendOtp.pending, state => {
         state.loading = true
       })
       builder.addCase(ResendOtp.fulfilled,  (state, action) => {
        state.loading = false
       })
       builder.addCase(ResendOtp.rejected, (state,action) => {
         state.loading = false
       })


       // api for verifyOtp---------
       builder.addCase(VerifyOtp.pending, state => {
        state.loading = true
      })
      builder.addCase(VerifyOtp.fulfilled,  (state, action) => {
       state.loading = false
      })
      builder.addCase(VerifyOtp.rejected, (state,action) => {
        state.loading = false
      })
    
    }
  })

  //Actions
  export const { signup_details } = authSlice.actions;
  
  //Reducer
  export default authSlice.reducer;