import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { Profileget } from "../../apis/api/ProfileApi";
import { FILE_URL } from "../../apis/api/Config";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import AboutDetails from "../../forms/AboutDetails";
import EditMemberDetails from "../../forms/EditDetailsofMember";
import Albums from "../../album/Album";
import Timelines from "../../timeline/Timeline";
import Addmember from "../../forms/Addmember";
import BasicServices from"../../forms/services";
import UploadProfile from "../../Profile/UploadImage/UploadProfile";
import UploadAlbum from "../../Profile/UploadImage/AlbumUploads";

function MemberDetails({}) {
  const fallbackImage = "https://s17.postimg.cc/ypm5ye95r/image.jpg";
  const fallbackImage2 =
    "https://www.ihna.edu.au/blog/wp-content/uploads/2022/10/user-dummy.png";
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [member, setMember] = useState();
  const [activeTab, setActiveTab] = useState("about");
  const [selectedLocation, setSelectedLocation] = useState("Indore");
  let userid = Cookies.get("userId");
  const memberId = id !== undefined ? id : Cookies.get("userId");
  const [showEditModal, setShowEditModal] = useState(false);

  const handleOpenEditModal = () => setShowEditModal(true);

  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };
  useEffect(() => {
    if (id || Cookies.get("userId")) {
      fetchData();
    }
  }, [id]);
  const fetchData = async () => {
    const response = await dispatch(Profileget({ member: memberId }));
    if (response.payload.data) {
      const memberData = { ...response.payload.data };
      if (memberData.company_country) {
        memberData.company_country = memberData.company_country.replace(
          /^\d+:/,
          ""
        );
      }
      setMember(memberData);
    }
  };
  const handleError = (e) => {
    e.target.src = fallbackImage;
  };
  const handleError1 = (e) => {
    e.target.src = fallbackImage2;
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const locations = {
    Indore: {
      address: "1234 Main St, Indore, India",
      contact: "+91-234-567-8901",
      mapSrc:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d98262.53654125537!2d75.81806435112211!3d22.71956842951952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fa2e8f0f7fdf%3A0x8e5a6c4a063e15a7!2sIndore%2C%20Madhya%20Pradesh%2C%20India!5e0!3m2!1sen!2sin!4v1602551559136!5m2!1sen!2sin",
    },
    Goa: {
      address: "5678 Beach Road, Goa, India",
      contact: "+91-987-654-3210",
      mapSrc:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d226434.95868590438!2d73.96473062857564!3d15.299326510840397!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbf11d0f6a4d41b%3A0x48c0f3d8ff4882a8!2sGoa%2C%20India!5e0!3m2!1sen!2sin!4v1602551728810!5m2!1sen!2sin",
    },
    Bangalore: {
      address: "9102 Tech Park, Bangalore, India",
      contact: "+91-678-345-6789",
      mapSrc:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d78032.36971159472!2d77.55217513857397!3d12.97159835221154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1677f34e9d09%3A0x4c8472e27b8a24a8!2sBangalore%2C%20Karnataka%2C%20India!5e0!3m2!1sen!2sin!4v1602551835822!5m2!1sen!2sin",
    },
    Mumbai: {
      address: "3456 Sea View, Mumbai, India",
      contact: "+91-345-678-9012",
      mapSrc:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d150373.82240741842!2d72.79922453026677!3d19.07609097516278!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c80a35ed0c3d%3A0x8041b82a9fd0c3f7!2sMumbai%2C%20Maharashtra%2C%20India!5e0!3m2!1sen!2sin!4v1602551967211!5m2!1sen!2sin",
    },
    Surat: {
      address: "7890 River Bank, Surat, India",
      contact: "+91-456-789-0123",
      mapSrc:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d145695.5631831644!2d72.75360117183425!3d21.170240344249614!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396b1e7e4b091093%3A0x6d10f6c53a6a62ef!2sSurat%2C%20Gujarat%2C%20India!5e0!3m2!1sen!2sin!4v1602552087411!5m2!1sen!2sin",
    },
  };
  const handleButtonClick = (location) => {
    setSelectedLocation(location);
  };

  const [modalShow, seteditShow] = React.useState(false);
  const [uploadshow, setuploadshow] = React.useState(false);
  const [aboutshow, setaboutshow] = React.useState(false);
  const [newmember, setnewmember] = React.useState(false);
  const [basicservice,setbasicservices]=React.useState(false);
  const [uploadAlbum,setuploadAlbum]=React.useState(false);


  return (
    <>
      <EditMemberDetails show={modalShow} onHide={() => seteditShow(false)} />
      <UploadProfile show={uploadshow} onHide={() => setuploadshow(false)} />
      <AboutDetails show={aboutshow} onHide={() => setaboutshow(false)}/>
      <Addmember show={newmember} onHide={() => setnewmember(false)}/>
      <BasicServices show={basicservice} onHide={() => setbasicservices(false)}/>
      <UploadAlbum  show={uploadAlbum} onHide={() => setuploadAlbum(false)}/>


      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12">
            <div className="container-fluid mt-4 bg-light">
              <div className="row">
                <div className="col-12">
                  <div className="banner-container position-relative mb-4">
                    <img
                      src={`${FILE_URL}/${member?.company_company_banner}`}
                      className="img-fluid banner-logo w-100"
                      onError={handleError}
                    />
                    {memberId == userid && (
                      <button
                        className="btn btn-secondary btn-sm camera-btn"
                        onClick={() => setuploadshow(true)}
                      >
                        <i className="fas fa-camera"></i>
                      </button>
                    )}
                    <div className="ms-4 profile Profile">
                      <img
                        src={`${FILE_URL}/${
                          member?.company_company_logo || "default-logo.png"
                        }`}
                        className="rounded-circle"
                        onError={handleError1}
                      />
                      {memberId == userid && (
                        <button
                          className="btn btn-secondary btn-sm  mtN-53 "
                          onClick={() => setuploadshow(true)}
                        >
                          <i className="fas fa-camera"></i>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <h3 className="profile-name text-theme ms-13">
                  {member?.company_company_name}
                </h3>
                <p className="profile-title text-muted ms-13">
                  <a
                    href={member?.company_website}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-decoration-none me-2"
                  >
                    {member?.company_website}
                  </a>
                  | {member?.company_contact}
                </p>
                <p className="profile-title text-muted ms-13 me-2">
                  {member?.company_company_name},{member?.company_address},
                  {member?.company_country} .
                </p>
              </div>

              <div className="form-row mt-5">
                <div className="form-group col-md-12">
                  <ul className="nav nav-tabs d-flex flex-row flex-wrap justify-content-start">
                    <li
                      className="i.border-member{
tem"
                    >
                      <button
                        className={`nav-link button-collapse custom-button ${
                          activeTab === "about" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("about")}
                      >
                        About
                      </button>
                    </li>
                    <li className="item">
                      <button
                        className={`nav-link button-collapse custom-button ${
                          activeTab === "photo" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("photo")}
                      >
                        Album
                      </button>
                    </li>
                    <li className="item">
                      <button
                        className={`nav-link button-collapse custom-button ${
                          activeTab === "timeline" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("timeline")}
                      >
                        Timeline
                      </button>
                    </li>
                  </ul>
                </div>
              </div>

              {/* Photo Tab */}
              {activeTab === "photo" && (
                <div className="container mt-3 mb-5" >
                  {memberId == userid && (
                    <div class="d-flex flex-row justify-content-between">
                      <button className="btn new-teammates-btn btn-sm bg-theme follow mt-3 mb-2"  onClick={() => setuploadAlbum(true)}>
                        <div className="d-flex align-items-center">
                          <i className="fas fa-plus"></i>
                          <p className="ps-1 pe-1 mb-0">Add Album</p>
                        </div>
                      </button>
                    </div>
                  )}
                  <Albums />
                </div>
              )}
              {activeTab === "timeline" && (
                <div className="container mt-3 mb-5">
                  <Timelines />
                </div>
              )}

              {/* About Tab */}
              {activeTab === "about" && (
                <div className="form-row">
                  <div className="profile-tab-item-section profile-tab-item-section-about bg-white">
                    <div className="main-blue f16 weight600 vmiddle text-md black ml-3 d-flex justify-content-evenly">
                      {member?.company_country} 5 offices
                    </div>
                    <div className="d-flex justify-content-end">
                      {memberId == userid && (
                        <button
                          className="btn-primary bg-theme pe-2 ps-2"
                          onClick={() => setaboutshow(true)}
                        >
                          Edit
                        </button>
                      )}
                    </div>

                    <hr className="bg-theme" />
                    <div className="d-flex flex-wrap mb-3">
                      {Object.keys(locations).map((location) => (
                        <button
                          key={location}
                          className={`btn btn-primary btn-sm bg-theme me-3 ${
                            selectedLocation === location ? "active" : ""
                          }`}
                          onClick={() => handleButtonClick(location)}
                        >
                          {location}
                        </button>
                      ))}
                    </div>

                    <div className="container mt-5 mb-3">
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <div className="contact-info bg-white p-3">
                            <p>
                              <strong className="text-theme">Address:</strong>{" "}
                              {locations[selectedLocation].address}
                            </p>
                            <p>
                              <strong className="text-theme">Contact:</strong>{" "}
                              {locations[selectedLocation].contact}
                            </p>
                          </div>
                        </div>

                        <div className="col-12 col-md-6">
                          <div className="map mt-3">
                            <iframe
                              src={locations[selectedLocation].mapSrc}
                              width="100%"
                              height="250"
                              style={{ border: 0 }}
                              allowFullScreen
                              loading="lazy"
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr />
                    <div>
                      <div className="col-12 ">
                        <h5 className="text-theme" >Members</h5>
                        {memberId == userid && (
                          <button
                            className="btn btn-primary bg-theme btn-sm mt-3"
                            onClick={() => setnewmember(true)}
                          >
                            <i className="fas fa-plus"></i> New Member
                          </button>
                        )}

                        <div className="container">
                          <div className="row">
                            {/* Card 1 */}
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                              <div className="our-team mt-5 border-member position-relative hover-card">
                                {memberId == userid && (
                                  <button
                                    className="btn btn-primary bg-theme btn-sm position-absolute top-0 end-0 m-2 edit-btn"
                                    onClick={() => seteditShow(true)}
                                  >
                                    <i className="fas fa-pen"></i>
                                  </button>
                                )}
                                <div className="picture">
                                  <img
                                    className="img-fluid"
                                    src="https://via.placeholder.com/150"
                                    alt="Profile"
                                    onError={handleError1}
                                  />
                                </div>
                                <div className="team-content mt-2">
                                  <h6 className="name">Aman</h6>
                                  <p className="">Developer</p>
                                  <p className="">
                                    <i className="fas fa-envelope text-theme"></i>{" "}
                                    John@example.com
                                  </p>
                                  <p className="">
                                    <i className="fas fa-phone text-theme"></i>{" "}
                                    +91567834754
                                  </p>
                                </div>
                                <div className="team-content">
                                  <button className="btn btn-primary btn-sm bg-theme mb-2">
                                    Chat
                                  </button>
                                </div>
                                <ul className="social">
                                  <li>
                                    <a
                                      href="mailto:aman123@gmail.com"
                                      className="fas fa-envelope"
                                      aria-hidden="true"
                                    ></a>
                                  </li>
                                  <li>
                                    <a
                                      href="tel:+1234567890"
                                      className="fas fa-address-book"
                                      aria-hidden="true"
                                    ></a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://wa.me/1234567890"
                                      className="fab fa-whatsapp"
                                      aria-hidden="true"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    ></a>
                                  </li>
                                </ul>
                              </div>
                            </div>

                            {/* Card 2 */}
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                              <div className="our-team mt-5 border-member position-relative hover-card">
                                {memberId == userid && (
                                  <button
                                    className="btn btn-primary bg-theme btn-sm position-absolute top-0 end-0 m-2 edit-btn"
                                    onClick={handleOpenEditModal}
                                  >
                                    <i className="fas fa-pen"></i>
                                  </button>
                                )}
                                <div className="picture">
                                  <img
                                    className="img-fluid"
                                    src="https://via.placeholder.com/150"
                                    alt="Profile"
                                    onError={handleError1}
                                  />
                                </div>
                                <div className="team-content mt-2">
                                  <h6 className="name">John</h6>
                                  <p className="">Designer</p>
                                  <p className="">
                                    <i className="fas fa-envelope text-theme"></i>{" "}
                                    John@example.com
                                  </p>
                                  <p className="">
                                    <i className="fas fa-phone text-theme"></i>{" "}
                                    +91567834754
                                  </p>
                                </div>
                                <div className="team-content">
                                  <button className="btn btn-primary btn-sm bg-theme mb-2">
                                    Chat
                                  </button>
                                </div>
                                <ul className="social">
                                  <li>
                                    <a
                                      href="mailto:aman123@gmail.com"
                                      className="fas fa-envelope"
                                      aria-hidden="true"
                                    ></a>
                                  </li>
                                  <li>
                                    <a
                                      href="tel:+1234567890"
                                      className="fas fa-address-book"
                                      aria-hidden="true"
                                    ></a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://wa.me/1234567890"
                                      className="fab fa-whatsapp"
                                      aria-hidden="true"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    ></a>
                                  </li>
                                </ul>
                              </div>
                            </div>

                            {/* Add more cards here as needed */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div class="mt-5 mb-3 position-relative">
                      {memberId == userid && (
                        <button
                          className="btn btn-primary bg-theme btn-sm position-absolute top-0 end-0 m-2 edit-btn"
                          onClick={() => setbasicservices(true)}
                        >
                          <i className="fas fa-pen"></i>
                        </button>
                      )}

                      <h4 class="mb-4">Services</h4>

                      <div class="row">
                        <div class="col-md-4 mb-3">
                          <div class="service-box d-flex align-items-center">
                            <i class="fas fa-plane fa-lg text-theme me-2"></i>
                            <p class="mb-0 ms-2">Air Freight</p>
                          </div>
                        </div>

                        <div class="col-md-4 mb-3">
                          <div class="service-box d-flex align-items-center">
                            <i class="fas fa-truck fa-lg text-theme me-2"></i>
                            <p class="mb-0 ms-2">Trucking</p>
                          </div>
                        </div>

                        <div class="col-md-4 mb-3">
                          <div class="service-box d-flex align-items-center">
                            <i class="fas fa-warehouse fa-lg text-theme me-2"></i>
                            <p class="mb-0 ms-2">Warehousing</p>
                          </div>
                        </div>

                        <div class="col-md-4 mb-3">
                          <div class="service-box d-flex align-items-center">
                            <i class="fas fa-ship fa-lg text-theme me-2"></i>
                            <p class="mb-0 ms-2">Ocean Freight</p>
                          </div>
                        </div>

                        <div class="col-md-4 mb-3">
                          <div class="service-box d-flex align-items-center">
                            <i class="fas fa-exclamation-triangle fa-lg text-theme me-2"></i>
                            <p class="mb-0 ms-2">Dangerous Goods</p>
                          </div>
                        </div>

                        <div class="col-md-4 mb-3">
                          <div class="service-box d-flex align-items-center">
                            <i class="fas fa-tasks fa-lg text-theme me-2"></i>
                            <p class="mb-0 ms-2">Projects</p>
                          </div>
                        </div>

                        <div class="col-md-4 mb-3">
                          <div class="service-box d-flex align-items-center">
                            <i class="fas fa-paw fa-lg text-theme me-2"></i>
                            <p class="mb-0 ms-2">Animals</p>
                          </div>
                        </div>

                        <div class="col-md-4 mb-3">
                          <div class="service-box d-flex align-items-center">
                            <i class="fas fa-boxes fa-lg text-theme me-2"></i>
                            <p class="mb-0 ms-2">LCL Services</p>
                          </div>
                        </div>

                        <div class="col-md-4 mb-3">
                          <div class="service-box d-flex align-items-center">
                            <i class="fas fa-train fa-lg text-theme me-2"></i>
                            <p class="mb-0 ms-2">Rail Freight</p>
                          </div>
                        </div>

                        <div class="col-md-4 mb-3">
                          <div class="service-box d-flex align-items-center">
                            <i class="fas fa-handshake fa-lg text-theme me-2"></i>
                            <p class="mb-0 ms-2">Brokerage</p>
                          </div>
                        </div>

                        <div class="col-md-4 mb-3">
                          <div class="service-box d-flex align-items-center">
                            <i class="fas fa-home fa-lg text-theme me-2"></i>
                            <p class="mb-0 ms-2">Relocations</p>
                          </div>
                        </div>

                        <div class="col-md-4 mb-3">
                          <div class="service-box d-flex align-items-center">
                            <i class="fas fa-shopping-cart fa-lg text-theme me-2"></i>
                            <p class="mb-0 ms-2">E-commerce</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Video Tab */}
              {activeTab === "video" && <p>video</p>}
            </div>
          </div>
        </div>
      </div>


    </>
  );
}

export default MemberDetails;
