import React, { useEffect } from "react";
import useFormValidation from "../hooks/useFormValidation";
import { useSelector, useDispatch } from "react-redux";
import { countrycode } from "../apis/api/DataApi";
import FileInput from "../hooks/FileInput";
import Cookies from "js-cookie";
import Select from "react-select";
import { SecondaryInsert } from "../apis/api/ProfileApi";
import { toast } from "react-toastify";

const CompanyRegistration = ({ handleCloseModal }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(countrycode());
  }, [dispatch]);

  const { country_code } = useSelector((state) => state.dataSlice);
  const countryCodeOptions = country_code?.data || [];
  const title = [
    { value: "Mr", label: "Mr" },
    { value: "Mrs", label: "Mrs" },
    { value: "Ms", label: "Ms" },
  ];
  const { formData, errors, handleChange, handleSubmit } = useFormValidation(
    {},
    validate
  );

  const selectcode = (selectedOption, name) => {
    handleChange({
      target: { name: name, value: selectedOption.value },
    });
  };

  const onSubmit = async (formData) => {
    const data = new FormData();
  
    const userId = Cookies.get("userId");
    const company_id = Cookies.get("company_id");
  
  
    Object.keys(formData).forEach((key) => {
      if (typeof formData[key] === "object" && formData[key] !== null) {
        Object.keys(formData[key]).forEach((subKey) => {
          data.append(`${key}[${subKey}]`, formData[key][subKey]);
        });
      } else {
        data.append(key, formData[key]);
      }
    });
  

    data.append("userId", userId);
    data.append("company_id", company_id);
  
   
    if (formData['Company[Member_logo]']) {
      data.append("Company[Member_logo]", formData['Company[Member_logo]']);
    }
  
   
    const response = await dispatch(SecondaryInsert(data));
  
    if (response?.payload?.status === "success") {
      handleCloseModal();
      toast.success("Member Inserted Successfully!");
    }
  };
  
  
  return (
    <div className="row mx-1">
      <form method="post" onSubmit={(e) => handleSubmit(e, onSubmit)}>
        <div class="form-container">
          <div class="d-block border rounded my-2 px-2 py-0">
            <div class="position-relative text-left">
              <span class="d-inline bg-primary text-white border rounded px-2 py-1 button-theme">
                <span class="text-bold">
                 Team Member
                  <span class="form-title no-border"></span>
                </span>
              </span>
              <div class="row">
                <div className="row mt-2">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        Title <span class="text-danger">*</span>
                      </label>
                      <Select
                        options={title}
                        onChange={(selectedOption) =>
                          selectcode(selectedOption, "key[Title]")
                        }
                      />
                      {errors.key?.Title && (
                        <span className="text-danger">{errors.key.Title}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        Name <span class="text-danger">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control border border-grey border-1 rounded-1  Removefocus"
                        placeholder="Name"
                        name="key[Name]"
                        value={formData.key?.Name}
                        onChange={handleChange}
                      />
                      {errors.key?.Name && (
                        <span className="text-danger">{errors.key.Name}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        Job Title <span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control border border-grey border-1 rounded-1  Removefocus"
                        placeholder="Job Title"
                        name="key[Job_Title]"
                        value={formData.key?.Job_Title}
                        onChange={handleChange}
                      />
                      {errors.key?.Job_Title && (
                        <span className="text-danger">
                          {errors.key.Job_Title}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className="form-label">
                        Phone Number Direct <span class="text-danger">*</span>
                      </label>
                      <div className="d-flex Removefocus">
                        <Select
                          options={countryCodeOptions}
                          onChange={(selectedOption) =>
                            selectcode(
                              selectedOption,
                              "key[country_code_direct]"
                            )
                          }
                          className="country-code-select  h-1rem"
                        />
                        <input
                          type="text"
                          className="form-control border border-grey border-1 rounded-1  Removefocus"
                          placeholder="contact"
                          name="key[direct_telephone]"
                          value={formData.key?.direct_telephone}
                          onChange={handleChange}
                        />
                      </div>
                      {errors.key?.country_code_direct && (
                        <span className="text-danger">
                          {errors.key.country_code_direct}
                        </span>
                      )}
                      {errors.key?.direct_telephone && (
                        <span className="text-danger">
                          {errors.key.direct_telephone}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">Extension</label>
                      <input
                        type="text"
                        className="form-control border border-grey border-1 rounded-1  Removefocus"
                        placeholder="Extension"
                        name="key[Extension]"
                        value={formData.key?.Extension}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className="form-label">
                        Phone Number Mobile <span class="text-danger">*</span>
                      </label>
                      <div className="d-flex Removefocus">
                        <Select
                          options={countryCodeOptions}
                          onChange={(selectedOption) =>
                            selectcode(selectedOption, "key[country_code]")
                          }
                          className="country-code-select  h-1rem"
                        />
                        <input
                          type="text"
                          className="form-control border border-grey border-1 rounded-1  Removefocus"
                          placeholder="contact"
                          name="key[Mobile]"
                          value={formData.key?.Mobile}
                          onChange={handleChange}
                        />
                      </div>
                      {errors.key?.country_code && (
                        <span className="text-danger">
                          {errors.key.country_code}
                        </span>
                      )}
                      {errors.key?.Mobile && (
                        <span className="text-danger">{errors.key.Mobile}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        Email address <span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control border border-grey border-1 rounded-1  Removefocus"
                        placeholder="Email address "
                        name="key[email]"
                        value={formData.key?.email}
                        onChange={handleChange}
                      />
                      {errors.key?.email && (
                        <span className="text-danger">{errors.key.email}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6  Removefocus">
                                    <FileInput
                                      name="Company[Member_logo]"
                                      label="Member logo"
                                      handleChange={handleChange}
                                    />
                                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12 mt-2">
          <button type="submit" className="btn button-theme">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default CompanyRegistration;

function validate(formData) {
  const errors = {};
  // key contact validation---

  if (!formData.key?.Title) {
    errors.key = { ...errors.key, Title: "Title is required" };
  }

  if (!formData.key?.Name) {
    errors.key = { ...errors.key, Name: "Name is required" };
  }

  if (!formData.key?.country_code_direct) {
    errors.key = {
      ...errors.key,
      country_code_direct: "Country code is required",
    };
  }

  if (!formData.key?.direct_telephone) {
    errors.key = { ...errors.key, direct_telephone: "Contact No is required" };
  }

  if (!formData.key?.country_code) {
    errors.key = { ...errors.key, country_code: "Country code is required" };
  }

  if (!formData.key?.Mobile) {
    errors.key = { ...errors.key, Mobile: "Contact No is required" };
  }

  if (!formData.key?.email) {
    errors.key = { ...errors.key, email: "Email is required" };
  }

  if (!formData.key?.Job_Title) {
    errors.key = { ...errors.key, Job_Title: "Job Title is required" };
  }
  return errors;
}
