import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL} from "./Config";

export const ProfileInsert = createAsyncThunk(
    "post/ProfileInsert",
    async (data) => { 
        let url = BASE_URL+"profile/insert";
        try{
            
            const response = (await axios.post(url, data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
       
            return Promise.reject(error)
        }
    }
)

export const Profileget = createAsyncThunk(
    "post/Profileget",
    async (data) => { 
        let url = BASE_URL+"profile/get";
        try{
            
            const response = (await axios.post(url, data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
           // console.log("getMainApps Error:", error)
            return Promise.reject(error)
        }
    }
)

export const Profilelist = createAsyncThunk(
    "post/Profilelist",
    async (data) => { 
        let url = BASE_URL+"profile/list";
        try{
            
            const response = (await axios.post(url, data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
          
            return Promise.reject(error)
        }
    }
)


export const SecondaryInsert = createAsyncThunk(
    "post/SecondaryInsert",
    async (data) => { 
        let url = BASE_URL+"profile/SecondaryInsert";
        try{
            const response = (await axios.post(url, data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)
export const getMemberDetailsByCompanyId = createAsyncThunk(
    "post/getMemberDetailsByCompanyId",
    async (data) => { 
        let url = BASE_URL+"profile/getMemberDetailsByCompanyId";
        try{
            const response = (await axios.post(url, data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)
export const memberlistForNavbar = createAsyncThunk(
    "post/memberlistForNavbar",
    async (data) => { 
        let url = BASE_URL+"profile/memberlistForNavbar";
        try{
            const response = (await axios.post(url, data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)

export const updateMemberDetails = createAsyncThunk(
    "post/updateMemberDetails",
    async (data) => { 
        let url = BASE_URL+"profile/updateMemberDetails";
        try{
            const response = (await axios.post(url, data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)



export const fetchUsers = createAsyncThunk(
    "users/fetchUsers",
    async (data) => { 
        let url = "https://clubnet.app/node/api/users";
        try{
            const response = (await axios.get(url)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)
