import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { membership } from "../apis/api/MembershipApi";
import Cookies from "js-cookie";

const Membership = () => {
  const [expanded, setExpanded] = useState({});

  const toggleExpand = (index) => {
    setExpanded((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const truncateContent = (content, lineLimit = 6) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = content;
  
    const table = tempDiv.querySelector("table");
    const ul = tempDiv.querySelector("ul");
  
    if (table) {
      const rows = table.querySelectorAll("tr");
      if (rows.length > lineLimit) {
        const truncatedTable = Array.from(rows).slice(0, lineLimit);
        const newTable = document.createElement("table");
        truncatedTable.forEach((row) => newTable.appendChild(row.cloneNode(true)));
        tempDiv.innerHTML = newTable.outerHTML;
      }
    } else if (ul) {
      const listItems = ul.querySelectorAll("li");
      if (listItems.length > lineLimit) {
        const truncatedList = Array.from(listItems).slice(0, lineLimit);
        const newUl = document.createElement("ul");
        truncatedList.forEach((item) => newUl.appendChild(item.cloneNode(true)));
        tempDiv.innerHTML = newUl.outerHTML;
      }
    } else {
      // Fallback to truncating plain text content based on word count
      const textContent = tempDiv.innerText;
      const words = textContent.split(" ");
      if (words.length > lineLimit) {
        tempDiv.innerText = words.slice(0, lineLimit).join(" ") + "...";
      }
    }
  
    return tempDiv.innerHTML;
  };
  
  // Function to check if content exceeds the line limit
  const exceedsLineLimit = (content, lineLimit = 6) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = content;
  
    const table = tempDiv.querySelector("table");
    const ul = tempDiv.querySelector("ul");
  
    if (table) {
      const rows = table.querySelectorAll("tr");
      return rows.length > lineLimit;
    } else if (ul) {
      const listItems = ul.querySelectorAll("li");
      return listItems.length > lineLimit;
    } else {
      // Check based on text content
      const words = tempDiv.innerText.split(" ");
      return words.length > lineLimit;
    }
  };


  const dispatch = useDispatch();

  useEffect(() => {
    const id = Cookies.get("company_id");
    const registration_type = Cookies.get("sorce_type");
    if (id) {
      dispatch(membership({ id, registration_type }));
    }
  }, [dispatch]);

  const { membership_detail } = useSelector((state) => state.membershipSlice);
  const plans = membership_detail.data || [];

  return (
    <div className="container-fluid mt-4">
    <h3>Manage Membership</h3>

    <div className="tab-content" id="membershipTabsContent">
      <div
        className="tab-pane fade show active"
        id="buyPlan"
        role="tabpanel"
        aria-labelledby="buyPlan-tab"
      >
        <div className="row d-flex">
          {plans.map((planlist, index) => (
            <div className="col-md-4 d-flex align-items-stretch" key={index}>
              <div className="card shadow-sm mb-4 border-0 w-100">
                <div className="card-header text-white text-center plCard fs-5 py-4 border-0">
                  {planlist.membership_name}
                </div>
                <div className="card-body d-flex flex-column">
                  <div className="extra-content  flex-grow-1">
                    <div className="d-flex justify-content-center align-items-center fs-1 mb-1 text-info fw-bold">
                      <p className="mb-0 me-2 fs-1">$</p>
                      <span>{planlist.fees}</span>
                    </div>
                    <div className="d-flex justify-content-center align-items-center fs-5 mb-3 text-theme fw-bold">
                      <p className="mb-0 me-2 text-theme">Months :</p>
                      <span>{planlist.duration_in_months}</span>
                    </div>

                    <div
                      className="text-secondary"
                      dangerouslySetInnerHTML={{
                        __html: expanded[index]
                          ? planlist.description
                          : truncateContent(planlist.description),
                      }}
                    />
                  </div>

                  {/* Show "View More" button if content exceeds the limit */}
                  {exceedsLineLimit(planlist.description) && (
                    <div className="text-center">
                      <a
                        href="#"
                        className="btn btn-link p-0 fw-bolder"
                        onClick={() => toggleExpand(index)}
                      >
                        {expanded[index] ? "View Less" : "View More"}
                      </a>
                    </div>
                  )}

                  <div className="d-flex justify-content-center mt-auto">
                    {planlist.current_plan == 0 && (
                      <button className="btn btn-outline-primary mt-2 d-block w-75">
                        Buy Plan!
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div
        className="tab-pane fade"
        id="planHistory"
        role="tabpanel"
        aria-labelledby="planHistory-tab"
      >
        <p>Plan history content goes here.</p>
      </div>
    </div>
  </div>
  );
};

export default Membership;
