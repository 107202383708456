import React, { useState, useEffect } from 'react';
import Cookies from "js-cookie";
import { useSelector, useDispatch } from "react-redux";
import { insertcomment ,fetchcomments} from '../apis/api/FeedsApi';
import Loading from "../hooks/Loading";
import { FILE_URL } from "../apis/api/Config";


const CommentArea = ({ id }) => {

  const dispatch = useDispatch();

  // const { loading } = useSelector((state) => state.feedsSlice);
  const [newComment, setNewComment] = useState('');
  const [comments, setcomments] = useState('');

  useEffect(() => {

    fetchdata()
    const interval = setInterval(()=>fetchdata(), 5000);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);
   
  const fetchdata = async () => {

    let response= await dispatch(fetchcomments({post_id:id}));
    
    if (response && response.payload && response.payload.data) {
      setcomments(response.payload.data)
    }
    
  };
  let loader;

  if (comments) {
    loader = false;
  } else {
    loader = true;
  }
 

  


  const handleSubmit = (event) => {
    event.preventDefault();
    if (newComment.trim()) {
      const currentTime = new Date();
      const timeString = currentTime.toLocaleTimeString();
      let member = Cookies.get("userId");
      let CompanyId= Cookies.get("company_id");
      member = JSON.parse(member);
      console.log(member);
    
      const newComments = { company_id: CompanyId,
         post_id:id,
         time: timeString, 
         text: newComment 
        };
      dispatch(insertcomment(newComments))
      setNewComment('');
     
    }
  };


  return (
    <>
    <Loading loading={loader} />
    <div className="coment-area">
  <ul className="we-comet">
    
    {comments.length > 0 ? (
      comments.map((comment) => (
        <li key={comment.post_comment_id}>
          <div className="comet-avatar">
            <img className="Image" src={`${FILE_URL}${comment.company_company_logo}`} alt="" />
          </div>
          <div className="we-comment">
            <div className="coment-head">
              <h5>
                <a href="time-line.html" title="">
                  {comment.company_company_name}
                </a>
              </h5>
              <span>{comment.post_comment_time}</span>
              <a className="we-reply" href="#" title="Reply">
                <i className="fa fa-reply"></i>
              </a>
            </div>
            <p>{comment.post_comment_text}</p>
          </div>
        </li>
      ))
    ) : (
      <li>No comments available.</li> 
    )}
    
   
  </ul>
  <div className="post-comt-box">
    <form method="post" onSubmit={handleSubmit}>
      <textarea 
        placeholder="Post your comment" 
        value={newComment}
        onChange={(e) => setNewComment(e.target.value)}
      ></textarea>
      
      <button type="submit" className="postbtn btn btn-primary fa-regular fa-paper-plane "> 
      <i class="">Comment</i>
      </button>
    </form>
  </div>
</div>

    </>
  );
};

export default CommentArea;




