import React from "react";
import  { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { submitSignUp } from "../apis/api/SignupApi";
import useFormValidation from '../hooks/useFormValidation';
import MVPLogo from "./MVP.png";
import "./Signin.css";
import Loading  from "../hooks/Loading";

const validate = (formData) => {
  const errors = {};
  if (!formData.first_name) errors.first_name = 'First Name is required';
  if (!formData.last_name) errors.last_name = 'Last Name is required';
  if (!formData.email) errors.email = 'Email is required';
  return errors;
};

const Signup = () => {
  const initialState = { first_name: '', last_name: '', email: '' };
  const { formData, errors, handleChange, handleSubmit } = useFormValidation(initialState, validate);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { signup_detail,loading } = useSelector((state) => state.authSlice);

  const onSubmit = () => {
    dispatch(submitSignUp(formData));
  };

  useEffect(() => {
    if (signup_detail.status === 'success') {
      navigate('/Thankyou');
    }
  }, [signup_detail, navigate]);

  return (
    <>
    <Loading loading={loading}/>
    <div className="container-fluid p-0">
      
      <div className="row g-0">
        <div className="col-xxl-3 col-lg-4 col-md-5">
          <div className="auth-full-page-content d-flex p-sm-5 p-4">
            <div className="w-100">
              <div className="d-flex flex-column h-100">
                <div className="mb-4 mb-md-5  d-flex align-items-center justify-content-around text-info">
                <img src="FLlogo.png" alt="" height="50" />
                </div>
                <div className="auth-content my-auto">
                  <div className="text-center">
                    <h5 className="mb-0">Welcome Back !</h5>
                    <p className="text-muted mt-2">
                      Sign in to continue to Freight Lounge.
                    </p>
                  </div>
                  <form className="mt-4 pt-2" onSubmit={(e) => handleSubmit(e, onSubmit)}>
                    <div className="mb-3">
                      <label className="form-label">First Name</label>
                      <input
                        type="text"
                        name="first_name"
                        className="form-control fc-enter"
                        id="first_name"
                        placeholder="Enter first name"
                        value={formData.first_name}
                        onChange={handleChange}
                      />
                      {errors.first_name && <span className="error-text">{errors.first_name}</span>}
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Last Name</label>
                      <input
                        type="text"
                        name="last_name"
                        className="form-control fc-enter"
                        id="last_name"
                        placeholder="Enter last name"
                        value={formData.last_name}
                        onChange={handleChange}
                      />
                      {errors.last_name && <span className="error-text">{errors.last_name}</span>}
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Email</label>
                      <input
                        type="email"
                        name="email"
                        className="form-control fc-enter"
                        id="email"
                        placeholder="Enter Email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      {errors.email && <span className="error-text">{errors.email}</span>}
                    </div>
                    <div className="mb-3">
                      <button
                        className="btn btn-primary w-100 waves-effect waves-light"
                        type="submit"
                      >
                        Signup
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xxl-9 col-lg-8 col-md-7 rt-col">
          <div className="auth-bg pt-md-5 p-4 d-flex">
            <div className="bg-overlay bg-primary1"></div>
            <ul className="bg-bubbles">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
            <div className="row justify-content-center align-items-center">
                <div className="col-xl-7">
                  <div className="p-0 p-sm-4 px-xl-0">
                    <div
                      id="reviewcarouselIndicators"
                      className="carousel slide"
                      data-bs-ride="carousel"
                    >
                      <div className="carousel-indicators carousel-indicators-rounded justify-content-start ms-0 mb-0">
                        <button
                          type="button"
                          data-bs-target="#reviewcarouselIndicators"
                          data-bs-slide-to="0"
                          className="active"
                          aria-current="true"
                          aria-label="Slide 1"
                        ></button>
                        <button
                          type="button"
                          data-bs-target="#reviewcarouselIndicators"
                          data-bs-slide-to="1"
                          aria-label="Slide 2"
                        ></button>
                        <button
                          type="button"
                          data-bs-target="#reviewcarouselIndicators"
                          data-bs-slide-to="2"
                          aria-label="Slide 3"
                        ></button>
                      </div>
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <div className="testi-contain text-white">
                            <i className="bx bxs-quote-alt-left text-success display-6"></i>
                            <h5 className="mt-4 fw-medium lh-base text-white">
                              “I feel confident imposing change on myself. It's
                              a lot more progressing fun than looking back.
                              That's why I ultricies enim at malesuada nibh diam
                              on tortor neaded to throw curve balls.”
                            </h5>
                            <div className="mt-4 pt-3 pb-5">
                              <div className="d-flex align-items-start">
                                <div className="flex-shrink-0">
                                  <img
                                    src="assets/images/users/avatar-1.jpg"
                                    className="avatar-md img-fluid rounded-circle"
                                    alt="..."
                                  />
                                </div>
                                <div className="flex-grow-1 ms-3 mb-4">
                                  <h5 className="font-size-18 text-white">
                                    Richard Drews
                                  </h5>
                                  <p className="mb-0 text-white-50">
                                    Web Designer
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="testi-contain text-white">
                            <i className="bx bxs-quote-alt-left text-success display-6"></i>
                            <h4 className="mt-4 fw-medium lh-base text-white">
                              “Our task must be to free ourselves by widening
                              our circle of compassion to embrace all living
                              creatures and the whole of quis consectetur nunc
                              sit amet semper justo. nature and its beauty.”
                            </h4>
                            <div className="mt-4 pt-3 pb-5">
                              <div className="d-flex align-items-start">
                                <div className="flex-shrink-0">
                                  <img
                                    src="assets/images/users/avatar-2.jpg"
                                    className="avatar-md img-fluid rounded-circle"
                                    alt="..."
                                  />
                                </div>
                                <div className="flex-grow-1 ms-3 mb-4">
                                  <h5 className="font-size-18 text-white">
                                    Rosanna French
                                  </h5>
                                  <p className="mb-0 text-white-50">
                                    Web Developer
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="testi-contain text-white">
                            <i className="bx bxs-quote-alt-left text-success display-6"></i>
                            <h4 className="mt-4 fw-medium lh-base text-white">
                              “I've learned that people will forget what you
                              said, people will forget what you did, but people
                              will never forget how donec in efficitur lectus,
                              nec lobortis metus you made them feel.”
                            </h4>
                            <div className="mt-4 pt-3 pb-5">
                              <div className="d-flex align-items-start">
                                <img
                                  src="https://media.istockphoto.com/id/1991161856/photo/portrait-of-a-smiling-man-looking-at-camera-and-smiling.webp?b=1&s=170667a&w=0&k=20&c=Whcy91b_aaffU8p1hG8FmxnFzDVciQSGAFV2iiG1IEo="
                                  className="avatar-md img-fluid rounded-circle"
                                  alt="..."
                                />
                                <div className="flex-1 ms-3 mb-4">
                                  <h5 className="font-size-18 text-white">
                                    Ilse R. Eaton
                                  </h5>
                                  <p className="mb-0 text-white-50">Manager</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <div className="row justify-content-center align-items-center">
              <div className="col-xl-7">
                <div className="p-0 p-sm-4 px-xl-0">{/* Content Here */}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Signup;