import React, { useState } from 'react';

const FileInput = ({ name, label, handleChange }) => {
  const [preview, setPreview] = useState(null);

  const onFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setPreview(URL.createObjectURL(file));
      handleChange({ target: { name, value: file } });
      const crossIcon = document.getElementById(`cross-${name}`);
      if (crossIcon) {
        crossIcon.classList.remove('d-none');
      }
    }
  };

  const handleReset = () => {
    setPreview(null);
    handleChange({ target: { name, value: null } });
    const crossIcon = document.getElementById(`cross-${name}`);
    if (crossIcon) {
      crossIcon.classList.add('d-none');
    }
  };

  const handleOpenFileDialog = () => {
    document.getElementById(name).click();
  };

  return (
    <div className="file-input-with-preview">
      <label className="form-label">{label}</label>
      <div className="input-group">
        <input
          type="file"
          className="form-control"
          id={name}
          name={name}
          onChange={onFileChange}
          style={{ display: 'none' }}
        />
        <input
          type="text"
          className="form-control"
          placeholder="NO File Choosen"
          value={preview ? preview.split('/').pop() : ''}
          readOnly
          onClick={handleOpenFileDialog}
        />
        <div className="input-group-append">
          <span className="input-group-text input-reset d-none" id={`cross-${name}`} onClick={handleReset}>
            <i className="fa-solid fa-times"></i>
          </span>
          <span className="input-group-text input-click " onClick={handleOpenFileDialog}>
            <i className="fa-solid fa-upload"></i>
          </span>
        </div>
      </div>
      {preview && (
        <div className="image-preview mt-2">
          <img src={preview} alt={`${name} preview`} style={{ maxWidth: '200px', maxHeight: '200px' }} />
        </div>
      )}
    </div>
  );
};

export default FileInput;