import React, { useState } from 'react';


const albumsData = [
  {
    id: 1,
    name: 'Album 1',
    photos: [
      'https://via.placeholder.com/150?text=Photo1',
      'https://via.placeholder.com/150?text=Photo2',
      'https://via.placeholder.com/150?text=Photo3',
    ],
    coverPhoto: 'https://via.placeholder.com/300?text=Album1', 
  },
  {
    id: 2,
    name: 'Album 2',
    photos: [
      'https://via.placeholder.com/150?text=PhotoA',
      'https://via.placeholder.com/150?text=PhotoB',
      'https://via.placeholder.com/150?text=PhotoC',
    ],
    coverPhoto: 'https://via.placeholder.com/300?text=Album2', 
  },
  {
    id: 3,
    name: 'Album 3',
    photos: [
      'https://via.placeholder.com/150?text=PhotoX',
      'https://via.placeholder.com/150?text=PhotoY',
      'https://via.placeholder.com/150?text=PhotoZ',
    ],
    coverPhoto: 'https://via.placeholder.com/300?text=Album3', 
  },

];

function Albums() {
  const [selectedAlbum, setSelectedAlbum] = useState(null);

  const handleAlbumClick = (album) => {
    setSelectedAlbum(album);
  };

  return (
    <div className="container">
    
      
     
      {selectedAlbum === null ? (
        <div className="albums-grid">
          {albumsData.map((album) => (
            <div
              key={album.id}
              className="album-card"
              onClick={() => handleAlbumClick(album)}
            >
              <img src={album.coverPhoto} alt={`Album ${album.id}`} />
            </div>
          ))}
        </div>
      ) : (
        <div>
            <div class="d-flex flex-row justify-content-end" >
         
          <button className="btn new-teammates-btn btn-sm bg-theme follow mt-3 mb-2" onClick={() => setSelectedAlbum(null)}>
                        <div className="d-flex align-items-center">
                          <i className="fa-solid fa-arrow-left"></i>
                          <p className="ps-1 pe-1 mb-0">Back</p>
                        </div>
                      </button>
          </div>
          <h2>{selectedAlbum.name}</h2>
          <div className="photos-grid">
            {selectedAlbum.photos.map((photo, index) => (
              <div key={index} className="photo">
                <img src={photo} alt={`Photo ${index + 1}`} />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Albums;
