import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ProtectedRoute from './hooks/ProtectedRoute';
import ProtectedOtp from './hooks/ProtectedOtp';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import Signup from './Authentication/Signup';
import Signin from './Authentication/Signin';
import Thankuou from './Authentication/Thankyou';
import Main from './feeds/Main2';
import MainLayout from './layout/MainLayout';
import MainNavFooter from './layout/MainNavFooter';
import Registration from './forms/Registration';
import OTP from './Authentication/OTP';
import News from './news/News';
import MemberRegistration from './forms/MemberRegistration';
// import MembershipPlan from './feeds/membership_plan/MembershipPlan';
import MembershipPlan from './membership/Membership';
import './App.css';
import Loading from './hooks/Loading';
import MemberDetails from './feeds/memberInformation/MemberDetail';
import Dashboard from './dashboard/dashboard';
import NavDashboard from './layout/NavDashboard';
import NavSidebar from './layout/NavSideebar';
import Team from "./layout/ManageTeams";
import EditDetails from  './forms/EditDetailsofMember';
import MainSidebar from './layout/MainSidebar';
import Profile from './Profile/profile1';
import FileuploadModal from './hooks/FileuploadModal';

function AppContent() {
  const userId = Cookies.get('userId');
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  
  useEffect(() => {
    console.log("check");
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, [location]);

  return (
    <>
      <Loading loading={loading} />
      <Routes>
        <Route path="/" element={userId ? <Navigate to="/home" /> : <Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/thankyou" element={<Thankuou />} />
        <Route path="/sidebar" element={<MainSidebar />} />
        <Route path="/registration/:id" element={<MemberRegistration />} />
        
        {/* Protected routes */}
        <Route element={<ProtectedRoute />}>
          <Route path="/feeds" element={<MainLayout><Main /></MainLayout>} />
          <Route path="/member" element={<MainNavFooter><Registration /></MainNavFooter>} />
          <Route path="/news" element={<NavSidebar><News /></NavSidebar>} />
          <Route path="/home" element={<NavDashboard><Dashboard /></NavDashboard>} />
          <Route path="/membership" element={<NavSidebar><MembershipPlan /></NavSidebar>} />
          <Route path="/member/:id" element={<MainNavFooter><MemberDetails/></MainNavFooter>} />
          <Route path="/Team" element={<NavSidebar><Team/></NavSidebar>} />
          <Route path="/profile" element={<NavSidebar><Profile/></NavSidebar>} />
          <Route path="/edit" element={<NavSidebar><EditDetails/></NavSidebar>} /> 
          {/* <Route path="/modal" element={<NavSidebar><FileuploadModal/></NavSidebar>} /> */}
        
        </Route>
        
        {/* Protected OTP route */}
        <Route element={<ProtectedOtp />}>
          <Route path="/otp" element={<OTP />} />
        </Route>
      </Routes>
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <AppContent />
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;