import React, { useState, useEffect } from "react";
import "./Signin.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useFormValidation from "../hooks/useFormValidation";
import { submitLogin } from "../apis/api/SignupApi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../hooks/Loading";
import Cookies from "js-cookie";

const validate = (formData) => {
  const errors = {};
  if (!formData.username) errors.username = "Username is required";
  if (!formData.password) errors.password = "Password is required";
  return errors;
};

function Signin() {
  const initialState = { username: "", password: "" };
  const { formData, errors, handleChange, handleSubmit } = useFormValidation(
    initialState,
    validate
  );

  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { Login_detail, loading } = useSelector((state) => state.authSlice);
 
  // let response;
  // const onSubmit = () => {
  //   response= await dispatch(submitLogin(formData));
    
  // };


  const onSubmit = async () => {
    try {
      const response = await dispatch(submitLogin(formData));
      console.log(response.payload.status);
      if (response.payload.status === "success") {
        const Id = response.payload.otp_id;
        const email = response.payload.email;
        Cookies.set("otpId", Id, { expires: 1 });
        let id= Cookies.get('otpId');
        // Cookies.set("userId", 51, { expires: 7 });
        
        // sessionStorage.setItem('otpId', Id);
        sessionStorage.setItem('otpemail', email);
        navigate("/otp");
      }
      
    } catch (error) {
      toast.error("Invalid credentials!");
      navigate("/");
      console.error(error);
    }
  };


  // useEffect(() => {
  //   if (Login_detail.status === "success") {

  //     if(Login_detail.message === "error")
  //       {
  //         toast.error("Invalid credentials!");
  //     navigate("/");
  //     }else{
  //     const Id = Login_detail.otp_id;
  //     const email = Login_detail.email;
  //     // const member_detail = JSON.stringify(Login_detail.member_detail);
  //     Cookies.set("otpId", Id, { expires: 7 });
  //     // sessionStorage.setItem('otpId', Id);
  //     sessionStorage.setItem('otpemail', email);
  //     navigate("/otp");

      // if (Login_detail.data.member_login_attempt === "0") {
      //   Cookies.set("userId", Id, { expires: 7 });
      //   Cookies.set("member_detail", member_detail, { expires: 7 });

      //   // navigate('/Member');
      // } else if (Login_detail.data.member_login_attempt == 1) {
      //   Cookies.set("userId", Id, { expires: 7 });

      //   Cookies.set("member_detail", member_detail, { expires: 7 });
      //   toast.success("Login successful!");
      //   navigate("/Home");
      // }
  //     }
  //   } 
  // }, [Login_detail, navigate]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
 
  return (
    <>
      <Loading loading={loading} />
      <div className="container-fluid p-0">
        <div className="row g-0">
          <div className="col-xxl-3 col-lg-4 col-md-5">
            <div className="auth-full-page-content d-flex p-sm-5 p-4">
              <div className="w-100">
                <div className="d-flex flex-column h-100">
                  <div className="mb-4 mb-md-5  d-flex align-items-center justify-content-around">
                  <img src="FLlogo.png" alt="" height="50" />

                  
                  </div>
                  <div className="auth-content my-auto">
                    <div className="text-center">
                      <h5 className="mb-0">Welcome Back !</h5>
                      <p className="text-muted mt-2">
                        Sign in to continue to Freight Lounge.
                      </p>
                    </div>
                    <form
                      className="mt-4 pt-2"
                      onSubmit={(e) => handleSubmit(e, onSubmit)}
                    >
                      <div className="mb-3">
                        <label className="form-label">Username</label>
                        <input
                          type="text"
                          name="username"
                          className="form-control fc-enter"
                          id="username"
                          placeholder="Enter username "
                          value={formData.username}
                          onChange={handleChange}
                        />
                        {errors.username && (
                          <span className="error-text">{errors.username}</span>
                        )}
                      </div>
                      <div className="mb-3">
                        <div className="d-flex align-items-start">
                          <div className="flex-grow-1">
                            <label className="form-label">Password</label>
                          </div>
                          <div className="flex-shrink-0">
                            <div>
                              <a href="auth-recoverpw" className="text-muted">
                                Forgot password?
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="input-group auth-pass-inputgroup">
                          <input
                            type={showPassword ? "text" : "password"}
                            name="password"
                            className="form-control fc-enter"
                            id="password"
                            placeholder="Enter password"
                            value={formData.password}
                            onChange={handleChange}
                          />
                          <button
                            className="btn btn-light shadow-none password-toggle-button"
                            type="button"
                            id="password-addon"
                            onClick={togglePasswordVisibility}
                          >
                            <i
                              className={
                                showPassword ? "fas fa-eye-slash" : "fas fa-eye"
                              }
                            ></i>
                          </button>
                        </div>
                        {errors.password && (
                          <span className="error-text">{errors.password}</span>
                        )}
                      </div>
                      <div className="row mb-4">
                        <div className="col">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="remember-check"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="remember-check"
                            >
                              Remember me
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>
                    </form>
                    <div className="mt-5 text-center">
                      <p className="text-muted mb-0">
                        Don't have an account?{" "}
                        <Link to="/Signup"> Signup now </Link>{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-9 col-lg-8 col-md-7 rt-col">
            <div className="auth-bg pt-md-5 p-4 d-flex">
              <div className="bg-overlay bg-primary1"></div>
              <ul className="bg-bubbles">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
              <div className="row justify-content-center align-items-center">
                <div className="col-xl-7">
                  <div className="p-0 p-sm-4 px-xl-0">
                    <div
                      id="reviewcarouselIndicators"
                      className="carousel slide"
                      data-bs-ride="carousel"
                    >
                      <div className="carousel-indicators carousel-indicators-rounded justify-content-start ms-0 mb-0">
                        <button
                          type="button"
                          data-bs-target="#reviewcarouselIndicators"
                          data-bs-slide-to="0"
                          className="active"
                          aria-current="true"
                          aria-label="Slide 1"
                        ></button>
                        <button
                          type="button"
                          data-bs-target="#reviewcarouselIndicators"
                          data-bs-slide-to="1"
                          aria-label="Slide 2"
                        ></button>
                        <button
                          type="button"
                          data-bs-target="#reviewcarouselIndicators"
                          data-bs-slide-to="2"
                          aria-label="Slide 3"
                        ></button>
                      </div>
                      {/* <div className="carousel-inner">
                        <div className="carousel-item active">
                          <div className="testi-contain text-white">
                            <i className="bx bxs-quote-alt-left text-success display-6"></i>
                            <h5 className="mt-4 fw-medium lh-base text-white">
                              “I feel confident imposing change on myself. It's
                              a lot more progressing fun than looking back.
                              That's why I ultricies enim at malesuada nibh diam
                              on tortor neaded to throw curve balls.”
                            </h5>
                            <div className="mt-4 pt-3 pb-5">
                              <div className="d-flex align-items-start">
                                <div className="flex-shrink-0">
                                  <img
                                    src="https://develop-c.cheshtainfotech.com/MVP/assets/images/users/avatar-1.jpg"
                                    className="avatar-md img-fluid rounded-circle"
                                    alt="..."
                                    style={{ width: "70px" }}
                                  />
                                </div>
                                <div className="flex-grow-1 ms-3 mb-4">
                                  <h5 className="font-size-18 text-white">
                                    Richard Drews
                                  </h5>
                                  <p className="mb-0 text-white-50">
                                    Web Designer
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="testi-contain text-white">
                            <i className="bx bxs-quote-alt-left text-success display-6"></i>
                            <h4 className="mt-4 fw-medium lh-base text-white">
                              “Our task must be to free ourselves by widening
                              our circle of compassion to embrace all living
                              creatures and the whole of quis consectetur nunc
                              sit amet semper justo. nature and its beauty.”
                            </h4>
                            <div className="mt-4 pt-3 pb-5">
                              <div className="d-flex align-items-start">
                                <div className="flex-shrink-0">
                                  <img
                                    src="assets/images/users/avatar-2.jpg"
                                    className="avatar-md img-fluid rounded-circle"
                                    alt="..."
                                  />
                                </div>
                                <div className="flex-grow-1 ms-3 mb-4">
                                  <h5 className="font-size-18 text-white">
                                    Rosanna French
                                  </h5>
                                  <p className="mb-0 text-white-50">
                                    Web Developer
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="testi-contain text-white">
                            <i className="bx bxs-quote-alt-left text-success display-6"></i>
                            <h4 className="mt-4 fw-medium lh-base text-white">
                              “I've learned that people will forget what you
                              said, people will forget what you did, but people
                              will never forget how donec in efficitur lectus,
                              nec lobortis metus you made them feel.”
                            </h4>
                            <div className="mt-4 pt-3 pb-5">
                              <div className="d-flex align-items-start">
                                <img
                                  src="assets/images/users/avatar-3.jpg"
                                  className="avatar-md img-fluid rounded-circle"
                                  alt="..."
                                />
                                <div className="flex-1 ms-3 mb-4">
                                  <h5 className="font-size-18 text-white">
                                    Ilse R. Eaton
                                  </h5>
                                  <p className="mb-0 text-white-50">Manager</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center align-items-center">
                <div className="col-xl-7">
                  <div className="p-0 p-sm-4 px-xl-0">{/* Content Here */}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signin;
