import React, { useState } from "react";
import MemberDetails from "../feeds/memberInformation/MemberDetail"

export default function Main() {
  const [activeMenu, setActiveMenu] = useState("about");

  const handleFileUpload = () => {
    document.getElementById("hiddenFileInput").click();
  };

  const renderContent = () => {
    switch (activeMenu) {
      case "about":
        return (
          <div>
            <div className="row">
              <div className="col-md-12 text-start">
                <p>You company's main information</p>
              </div>

              <div className="col-md-12">
                <div className="position-relative">
                  <img
                    src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"
                    alt=""
                    className="img-fluid profileCoverImage w-100  h-25 "
                  />

                  <img
                    src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909__340.png"
                    alt=""
                    className="img-fluid profilePicImage   h-75 position-absolute bottom-0 start-0 m-2"
                  />

                  <i class="fa-solid fa-camera text-secondary profileUploadIcon"></i>

                  <button
                    type="file"
                    id="uploadfFileInput"
                    className="btn btn-primary position-absolute top-0 end-0 m-2"
                  >
                    Change Cover
                  </button>
                </div>
                <input
                  type="text"
                  className="form-control mt-3"
                  placeholder="Company Name"
                />
                <input
                  type="text"
                  className="form-control mt-3"
                  placeholder="Legal Name"
                />

                <div className=" text-start mt-2">
                  <p>Only applicable if the company name is DBA</p>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="website"
                    />
                  </div>

                  <div className="col-md-6">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                    >
                      <option selected disabled>
                        Company Size
                      </option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-12 mt-3">
                  <textarea
                    class="form-control"
                    rows="3"
                    placeholder="Briefly describe your company"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        );
      case "services":
        return (
          <div>
            <div className="row">
              <div className="col-md-12">
                <p>Select all the services your company provides</p>
              </div>
              <div className="row mt-3">
                <div className="col-md-6 ">
                  <input
                    type="checkbox"
                    className="btn-check air-freight-check"
                    id="btn-air-freight"
                    autoComplete="off"
                  />
                  <label
                    className="btn btn-outline-primary w-75 air-freight-label text-start"
                    htmlFor="btn-air-freight"
                  >
                    <i class="fa-solid fa-plane"></i> Air Freight
                  </label>
                </div>
                <div className="col-md-6">
                  <input
                    type="checkbox"
                    className="btn-check ocean-freight-check"
                    id="btn-ocean-freight"
                    autoComplete="off"
                  />
                  <label
                    className="btn btn-outline-primary w-75 ocean-freight-label text-start"
                    htmlFor="btn-ocean-freight"
                  >
                    <i class="fa-solid fa-ferry"></i> Ocean Freight
                  </label>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-6">
                  <input
                    type="checkbox"
                    className="btn-check lcl-services-check"
                    id="btn-lcl-services"
                    autoComplete="off"
                  />
                  <label
                    className="btn btn-outline-primary w-75 lcl-services-label text-start"
                    htmlFor="btn-lcl-services"
                  >
                    <i class="fa-solid fa-satellite-dish"></i> LCL Services
                  </label>
                </div>
                <div className="col-md-6">
                  <input
                    type="checkbox"
                    className="btn-check brokerage-check"
                    id="btn-brokerage"
                    autoComplete="off"
                  />
                  <label
                    className="btn btn-outline-primary w-75 brokerage-label text-start"
                    htmlFor="btn-brokerage"
                  >
                    <i class="fa-solid fa-money-bill-wheat"></i> Brokerage
                  </label>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-6">
                  <input
                    type="checkbox"
                    className="btn-check trucking-check"
                    id="btn-trucking"
                    autoComplete="off"
                  />
                  <label
                    className="btn btn-outline-primary w-75 trucking-label text-start"
                    htmlFor="btn-trucking"
                  >
                    <i class="fa-solid fa-truck-fast"></i> Trucking
                  </label>
                </div>
                <div className="col-md-6">
                  <input
                    type="checkbox"
                    className="btn-check time-critical-check"
                    id="btn-time-critical"
                    autoComplete="off"
                  />
                  <label
                    className="btn btn-outline-primary w-75 time-critical-label text-start"
                    htmlFor="btn-time-critical"
                  >
                    <i class="fa-solid fa-truck-droplet"></i> Time Critical
                  </label>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-6">
                  <input
                    type="checkbox"
                    className="btn-check perishables-check"
                    id="btn-perishables"
                    autoComplete="off"
                  />
                  <label
                    className="btn btn-outline-primary w-75 perishables-label text-start"
                    htmlFor="btn-perishables"
                  >
                    <i class="fa-solid fa-bowl-food"></i> Perishables
                  </label>
                </div>
                <div className="col-md-6">
                  <input
                    type="checkbox"
                    className="btn-check dangerous-goods-check"
                    id="btn-dangerous-goods"
                    autoComplete="off"
                  />
                  <label
                    className="btn btn-outline-primary w-75 dangerous-goods-label text-start"
                    htmlFor="btn-dangerous-goods"
                  >
                    <i class="fa-solid fa-triangle-exclamation"></i> Dangerous
                    Goods
                  </label>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-6">
                  <input
                    type="checkbox"
                    className="btn-check projects-check"
                    id="btn-projects"
                    autoComplete="off"
                  />
                  <label
                    className="btn btn-outline-primary w-75 projects-label text-start"
                    htmlFor="btn-projects"
                  >
                    <i class="fa-solid fa-briefcase"></i> Projects
                  </label>
                </div>
                <div className="col-md-6">
                  <input
                    type="checkbox"
                    className="btn-check relocations-check"
                    id="btn-relocations"
                    autoComplete="off"
                  />
                  <label
                    className="btn btn-outline-primary w-75 relocations-label text-start"
                    htmlFor="btn-relocations"
                  >
                    <i class="fa-solid fa-cart-flatbed-suitcase"></i>{" "}
                    Relocations
                  </label>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-6">
                  <input
                    type="checkbox"
                    className="btn-check warehousing-check"
                    id="btn-warehousing"
                    autoComplete="off"
                  />
                  <label
                    className="btn btn-outline-primary w-75 warehousing-label text-start"
                    htmlFor="btn-warehousing"
                  >
                    <i class="fa-solid fa-warehouse"></i> Warehousing
                  </label>
                </div>
                <div className="col-md-6">
                  <input
                    type="checkbox"
                    className="btn-check live-animals-check"
                    id="btn-live-animals"
                    autoComplete="off"
                  />
                  <label
                    className="btn btn-outline-primary w-75 live-animals-label text-start"
                    htmlFor="btn-live-animals"
                  >
                    <i class="fa-solid fa-hippo"></i> Live Animals
                  </label>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-6">
                  <input
                    type="checkbox"
                    className="btn-check logistics-check"
                    id="btn-logistics"
                    autoComplete="off"
                  />
                  <label
                    className="btn btn-outline-primary w-75 logistics-label text-start"
                    htmlFor="btn-logistics"
                  >
                    <i class="fa-solid fa-hands-holding-circle"></i> Integrated
                    Logistics
                  </label>
                </div>
                <div className="col-md-6">
                  <input
                    type="checkbox"
                    className="btn-check pharma-check"
                    id="btn-pharma"
                    autoComplete="off"
                  />
                  <label
                    className="btn btn-outline-primary w-75 pharma-label text-start"
                    htmlFor="btn-pharma"
                  >
                    <i class="fa-solid fa-prescription-bottle-medical"></i>{" "}
                    Pharma
                  </label>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-6">
                  <input
                    type="checkbox"
                    className="btn-check rail-freight-check"
                    id="btn-rail-freight"
                    autoComplete="off"
                  />
                  <label
                    className="btn btn-outline-primary w-75 rail-freight-label text-start"
                    htmlFor="btn-rail-freight"
                  >
                    <i class="fa-solid fa-train"></i> Rail Freight
                  </label>
                </div>
                <div className="col-md-6">
                  <input
                    type="checkbox"
                    className="btn-check e-commerce-check"
                    id="btn-e-commerce"
                    autoComplete="off"
                  />
                  <label
                    className="btn btn-outline-primary w-75 e-commerce-label text-start"
                    htmlFor="btn-e-commerce"
                  >
                    <i class="fa-solid fa-cart-shopping"></i> E-Commerce
                  </label>
                </div>
              </div>

              <div className="row mt-3">
                <p>Add custom services you are an expert in</p>
                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control w-75"
                    placeholder="Custom Services 1"
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control w-75"
                    placeholder="Custom Services 2"
                  />
                </div>
              </div>
            </div>
          </div>
        );
      case "locations":
        return (
          <div>
            <div className="row">
              <div className="col-md-9 text-start">
                <p>
                  Add and edit information about your office locations <br />
                  <span className="">
                    {" "}
                    Active offices appear on the Member Profile and make your
                    company visible to other members for any type of action
                    related to that location
                  </span>
                </p>
              </div>
              <div className="col-md-9 text-start">
                <button type="button" className="btn btn-outline-primary">
                  <i class="fa-solid fa-plus"></i> New Office
                </button>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-8 text-start">
                <p>
                  Australlia(1 office){" "}
                  <span className="text-danger">
                    {" "}
                    <i class="fa-solid fa-triangle-exclamation"></i> Exposed to
                    Financial risk
                  </span>{" "}
                </p>
              </div>
              <div className="col-md-4">
                <p className="bg-primary rounded">Add Financial Protection</p>
              </div>

              <div className="col-md-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col" className=" text-primary w-auto">
                        Status
                      </th>
                      <th scope="col" className=" text-primary">
                        Location
                      </th>
                      <th scope="col" className=" text-primary ">
                        Contacts
                      </th>
                      <th scope="col" className=" text-primary">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">
                        <p className="text-primary bg-info rounded-pill">
                          Active
                        </p>
                      </th>
                      <td>
                        Melbourne <span className="">(main)</span>
                      </td>
                      <td></td>

                      <td className="row d-flex justify-content-center">
                        <div className="col-md-2 ">
                          <i class="fa-solid fa-pencil"></i>
                        </div>
                        <div className="col-md-2 text-end">
                          <i class="fa-regular fa-trash-can"></i>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>Jacob</td>
                      <td>Thornton</td>
                      <td>@fat</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td colspan="2">Larry the Bird</td>
                      <td>@twitter</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        );
      case "certifications":
        return <div>Certifications Page Content</div>;
      case "media":
        return (
          <div>
            <div className="row">
              <div className="col-md-12 text-start">
                <p>Add media to better show your company</p>
              </div>
              <div className="col-md-12 text-start">
                <input
                  type="file"
                  id="hiddenFileInput"
                  style={{ display: "none" }}
                  onChange={(e) => e.target.files[0]}
                />

                <button
                  type="button"
                  className="btn btn-outline-primary fw-bold"
                  onClick={handleFileUpload}
                >
                  <i class="fa-regular fa-file-image"></i> Add Media
                </button>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <img
                  src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"
                  className="w-100"
                  alt="..."
                />
              </div>
              <div className="col-md-4">
                <img
                  src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"
                  className="w-100"
                  alt="..."
                />
              </div>
              <div className="col-md-4">
                <img
                  src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"
                  className="w-100"
                  alt="..."
                />
              </div>
            </div>
          </div>
        );
      case "files":
        return (
          <div>
            <div className="row">
              <div className="col-md-12 text-start">
                <p>Add a picture or video</p>
              </div>
              <div className="col-md-6">
                <div className="bg-info h-100">
                  <div className="d-flex justify-content-center align-items-center h-100">
                    <i class="fa-solid fa-image fs-1"></i>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12 text-start">
                    <p>Select what you want to add</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <input
                      type="checkbox"
                      className="btn-check photo-check"
                      id="btn-photo"
                      autoComplete="off"
                    />
                    <label
                      className="btn btn-outline-primary w-100 photo-label text-center"
                      htmlFor="btn-photo"
                    >
                      <i class="fa-solid fa-camera"></i> Photo
                    </label>
                  </div>
                  <div className="col-md-6">
                    <input
                      type="checkbox"
                      className="btn-check video-check"
                      id="btn-video"
                      autoComplete="off"
                    />
                    <label
                      className="btn btn-outline-primary w-100 video-label text-center "
                      htmlFor="btn-video"
                    >
                      <i class="fa-solid fa-clapperboard"></i> Video
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <div className="">
                      <input type="file" className="form-control" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <div className="">
                      <textarea
                        rows="4"
                        placeholder="Description"
                        className="form-control"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return <div>Welcome! Select a menu to see the content.</div>;
    }
  };

  return (
    <>
     <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 bg-light text-center">
          <div className="profile-container mt-1 bg-white">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 text-center">
                  <div className="modal-body">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-md-2 bg-theme-colr min-vh-100 d-flex flex-column justify-content-start">
                          <div>
                            <ul
                              className="mt-5 d-flex flex-column"
                              style={{ gap: "30px" }}
                            >
                              <li
                                className={`list-group-item border-0 ${
                                  activeMenu === "about" ? "active" : ""
                                }`}
                                onClick={() => setActiveMenu("about")}
                                style={{ cursor: "pointer" }}
                              >
                                Company Info
                              </li>
                              <li
                                className={`list-group-item border-0 ${
                                  activeMenu === "locations" ? "active" : ""
                                }`}
                                onClick={() => setActiveMenu("locations")}
                                style={{ cursor: "pointer" }}
                              >
                                Locations
                              </li>
                              <li
                                className={`list-group-item border-0 ${
                                  activeMenu === "services" ? "active" : ""
                                }`}
                                onClick={() => setActiveMenu("services")}
                                style={{ cursor: "pointer" }}
                              >
                                Services
                              </li>
                            </ul>
                            <ul
                              className="mt-4 d-flex flex-column"
                              style={{ gap: "20px" }}
                            >
                              <li
                                className={`list-group-item border-0 ${
                                  activeMenu === "media" ? "active" : ""
                                }`}
                                onClick={() => setActiveMenu("media")}
                                style={{ cursor: "pointer" }}
                              >
                                Media
                              </li>
                              <li
                                className={`list-group-item border-0 ${
                                  activeMenu === "files" ? "active" : ""
                                }`}
                                onClick={() => setActiveMenu("files")}
                                style={{ cursor: "pointer" }}
                              >
                                Files
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="col-md-9 border-5">{renderContent()}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Additional profile container and teammate info */}
        <div className="profile-container">
          {/* Teammates Section */}
          <div className="row mt-5">
            <div className="col-md-12 text-start mb-5">
              <h6 className="ms-3">Teammates</h6>
              <div className="profileTabTeammateImages mt-4 ms-3">
                <img
                  src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"
                  alt="Teammate"
                />
              </div>
              <div className="mt-5 ms-3">
                <a href="#" className="text-primary mt-2 mb-5">
                  Manage <i className="fa-solid fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
       
 
    </>
  );
}
