import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import Navbar from "./Navbar";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { Profileget } from "../apis/api/ProfileApi";
import { useParams } from 'react-router-dom';


const NavDashboard = ({ children }) => {
 
  return (
    <>

      <div className="container-fluid">
        <div className="row row1">
        <div className="col-lg-2" >
              <Sidebar/>      
            </div>
          <div className="col-md-10 margin-top MainComponent">
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default NavDashboard;
