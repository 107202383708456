import React, { useState, useEffect } from 'react';
import Navbar from "./Navbar";
import Sidebar from './Sidebar';
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { Profileget } from "../apis/api/ProfileApi";
import { useParams } from 'react-router-dom';
import SearchSidebar from "./SearchSidebar"

const MainNavFooter = ({ children }) => {
  // const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  // const [member, setMember] = useState(null);
  // const [memberId, setMemberId] = useState('');
  // const [initialize, setInitialize] = useState(false);
  // const { id } = useParams();
  // const dispatch = useDispatch();

  // const toggleSidebar = () => {
  //   setIsSidebarOpen(!isSidebarOpen);
  // };

  // useEffect(() => {
  //   if (!initialize) {
  //     fetchData();
  //     setInitialize(true);
  //   }
  // }, []);

  // useEffect(() => {
  //   const fetchedMemberId = id !== undefined ? id : Cookies.get('userId');
  //   setMemberId(fetchedMemberId);
  // }, [id]); 

  // useEffect(() => {
  //   if (initialize) {
  //     fetchData();
  //   }
  // }, [memberId]); 

  // const fetchData = async () => {
  //   let memberId = id !== undefined ? id : Cookies.get('userId');
  //   const response = await dispatch(Profileget({ member: memberId }));

  //   if (response.payload.data) {
  //     const memberData = { ...response.payload.data };
  //     if (memberData.company_country) {
  //       memberData.company_country = memberData.company_country.replace(/^\d+:/, '');
  //     }
  //     setMember(memberData);
  //   }
  // };

  return (
    <>

      <div className="container-fluid">
        <div className="row row2">
          <div className="col-md-2 ">
            <Sidebar  />
          </div>
          <div className="col-md-10 margin-top MainComponent">
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default MainNavFooter;
