import React, { useEffect, useState, useRef } from "react";
import Cookies from 'js-cookie';
import { useNavigate, useLocation,Link } from 'react-router-dom';
import { toast } from "react-toastify";
import { memberlistForNavbar } from "../apis/api/ProfileApi";
import { useDispatch, useSelector } from "react-redux";
import { FILE_URL } from "../apis/api/Config";
import { Profileget } from "../apis/api/ProfileApi";


const Navbar = ({data}) => {
  const fallbackImage = "https://www.ihna.edu.au/blog/wp-content/uploads/2022/10/user-dummy.png";
      const memberId = Cookies.get("userId");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [member, setMember] = useState(data);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isInputEmpty, setInputEmpty] = useState(true);
  const searchBoxRef = useRef(null);
  const [Query, setQuery] = useState("");
  const { MemberList } = useSelector(state => state.profileSlice);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [showSettings, setShowSettings] = useState(false);

  const member_detail = JSON.parse(Cookies.get('userJSON'));
  console.log(member_detail);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const memberId = Cookies.get("userId");
    const response = await dispatch(Profileget({ member: memberId }));
    if (response.payload.data) {
      const memberData = { ...response.payload.data };
      if (memberData.company_country) {
        memberData.company_country = memberData.company_country.replace(
          /^\d+:/,
          ""
        );
      }
      setMember(memberData);
    }
  };
  useEffect(() => {
    dispatch(memberlistForNavbar())
      .catch(error => {
        toast.error("Failed to fetch profiles.");
      });
  }, [dispatch]);

  useEffect(() => {
    if (searchBoxRef.current) {
      searchBoxRef.current.value = ''; // Clear the search input field
    }
    setQuery(""); // Reset the query state
    setDropdownVisible(false); // Hide the dropdown
  }, [location.pathname]);

  const handleInputChange = (event) => {
    const value = event.target.value || ""; 
    setQuery(value);
    setInputEmpty(value === '');
    setDropdownVisible(value !== '');
  };

  const handleClickOutside = (event) => {
    if (searchBoxRef.current && !searchBoxRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  const handleMemberClick = (id) => {
    setDropdownVisible(false); 
    navigate(`/member/${id}`);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleSidebar = () => {
    const sidebar = document.getElementById("sidebar");
    if (sidebar) {
      sidebar.classList.toggle("active");
      sidebar.classList.toggle("d-none", !sidebar.classList.contains("active"));
    }
  };

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);

  const toggleSettings = () => setShowSettings(!showSettings);

  const handleClick = (event) => {
    event.preventDefault();
    Cookies.remove('userId');
    Cookies.remove('member_detail');
    toast.success("Logout successful!");
    navigate("/");
  };

  const filteredProfiles = MemberList.filter(profile => {
    const memberName = profile.member_name?.toLowerCase() || ""; 
    const companyName = profile.company_name?.toLowerCase() || ""; 
    return memberName.includes(Query.toLowerCase()) || companyName.includes(Query.toLowerCase());
  });

  const handleImageError = (e) => {
    e.target.src = fallbackImage; 
  };
  

  return (
    <nav className="navbar navbar-expand-lg navbar-light w-100">
      <div className="container-fluid">
        <button
          type="button"
          id="sidebarCollapse"
          className="btn button-theme d-inline-block d-lg-none ml-auto"
          onClick={toggleSidebar}
        >
          <i className="fa fa-bars"></i>
          <span className="sr-only">Toggle Menu</span>
        </button>
        <img src={`${process.env.PUBLIC_URL}/FL-select-logo2.png`} style={{ width: "100px" }} alt="Logo" />

        <button
          className="btn btn-dark d-inline-block d-lg-none ml-auto"
          type="button"
          onClick={toggleCollapse}
        >
          <i className="fa fa-bars"></i>
        </button>

        <div>
          <div className="input-group search-box" ref={searchBoxRef}>
            <div className="form-outline">
              <input
                type="search"
                placeholder="Search"
                className="form-control Search"
                onChange={handleInputChange}
                value={Query}
              />
              {isDropdownVisible && (
                <div className="dropdown-menu show dms searchbox">
                  {filteredProfiles.map((profile, index) => (
                    <a
                      className="dropdown-item searchNames d-flex"
                      href="#"
                      key={index}
                      onClick={() => handleMemberClick(profile.member_id)}
                    >
                      <img
                        src={`${FILE_URL}/${profile.company_logo}`}
                        alt={profile.member_name}
                        className="dropdown-item-image"
                        onError={handleImageError} 
                      />
                      <div className="dropdown-item-content">
                        <div className="dropdown-item-name">{profile.member_name}</div>
                        <div className="dropdown-item-company">{profile.company_name}</div>
                      </div>
                    </a>
                  ))}
                </div>
              )}
            </div>
            {isInputEmpty && (
              <button type="button" className="bt">
                <i className="fa fa-search"></i>
              </button>
            )}
          </div>
        </div>

        <div className={`collapse navbar-collapse ${isCollapsed ? "" : "show"}`} id="navbarSupportedContent">
          <ul className="nav navbar-nav ml-auto w-100 fs-small">
            <li className="nav-item active mr5">
            <Link to="/home" className="nav-link" href="">
                <i className="fa fa-home"></i>
                <span className="ms-1">Home</span>
              </Link>
            </li>
            <li className="nav-item mr5">
            <Link to="/feeds"className="nav-link">
                <i className="fa fa-american-sign-language-interpreting"></i>
                <span className="ms-1">Feeds</span>
              </Link>
            </li>
            <li className="nav-item mr5">
            <Link to="/membership"className="nav-link" >
                <i className="fa fa-address-card"></i>
                <span className="ms-1">Membership</span>
              </Link>
            </li>
            {member_detail.membership_plans_direct_chat === 1 && (
              <li className="nav-item mr5">
                <Link to="/" className="nav-link">
                  <i className="fa fa-envelope"></i>
                  <span className="ms-1">Message</span>
                </Link>
              </li>
            )}
            <li className="nav-item mr5">
            <Link to="/"className="nav-link" >
                <i className="fa fa-users"></i>
                <span className="ms-1">Meetings</span>
              </Link>
            </li>

            <li className="nav-item profilee">
              <a className="nav-link" href="#" onClick={toggleSettings}>
                <div className="user-img">
                <img
                  src={`${FILE_URL}/${
                    member?.member_member_logo || "default-logo.png"
                  }`}
                  className="Image"
                  onError={handleImageError}
                />
                  <span className="ms-1">Me</span>
                  {showSettings && (
                    <div className="upper-box">
                      <ul className="profilepage1">
                        <li className="profilepage">
                        
                          <i className="fa fa-podcast"></i>
                          <Link to="/profile"  className="ftaw" style={{ textDecoration: "none" }}>Profile</Link>
                        </li>
                        {/* <li className="profilepage">
                          <i className="fa fa-podcast"></i>
                          <Link to="/404.html"  className="ftaw" style={{ textDecoration: "none" }}>Log</Link>
                        </li> */}
                        <li  className="profilepage">
                          <i className="fa fa-sign-out"></i>
                          <Link to="/" className="ftaw" style={{ textDecoration: "none" }} onClick={handleClick}>Logout</Link>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
