import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import React, { useState, useRef } from "react";
// import 'upload.css';
import './upload.css'

function UploadProfile(props) {
  const [fileName, setFileName] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [fileFlag, setFileFlag] = useState(0);
  const [progressWidth, setProgressWidth] = useState(0);
  const [cannotUpload, setCannotUpload] = useState(false);

  const fileInputRef = useRef(null);
  const uploadedFileRef = useRef(null);
  const progressBarRef = useRef(null);

  const isAdvancedUpload =
    (function () {
      const div = document.createElement("div");
      return (
        ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
        "FormData" in window &&
        "FileReader" in window
      );
    })() || false;

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFileName(file.name);
    setFileSize((file.size / 1024).toFixed(1) + " KB");
    uploadedFileRef.current.style.display = "flex";
    setProgressWidth(0);
    setFileFlag(0);
  };

  const handleUploadClick = () => {
    if (fileInputRef.current.value) {
      if (fileFlag === 0) {
        setFileFlag(1);
        let width = 0;
        const intervalId = setInterval(() => {
          if (width >= 390) {
            clearInterval(intervalId);
            document.querySelector(
              ".upload-button"
            ).innerHTML = `<span class="material-icons-outlined upload-button-icon">check_circle</span> Uploaded`;
          } else {
            width += 5;
            setProgressWidth(width);
          }
        }, 50);
      }
    } else {
      setCannotUpload(true);
    }
  };

  const handleCancelAlert = () => {
    setCannotUpload(false);
  };

  const handleRemoveFile = () => {
    uploadedFileRef.current.style.display = "none";
    fileInputRef.current.value = "";
    setFileName("");
    setFileSize("");
    setFileFlag(0);
    setProgressWidth(0);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer.files;
    fileInputRef.current.files = files;
    handleFileChange({ target: { files: files } });
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          upload
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

      <form className="form-container" encType="multipart/form-data">
      <div className="upload-files-container">
        <div
          className="drag-file-area"
          onDrop={isAdvancedUpload ? handleDrop : undefined}
          onDragOver={(e) => e.preventDefault()}
        >
         
          <h5 className="dynamic-message">Drag & drop any file here</h5>
          <label className="label me-5 w-100">
            or
            <span className="browse-files">
              <input
                type="file"
                className="default-file-input"
                ref={fileInputRef}
                onClick={() => (fileInputRef.current.value = "")}
                onChange={handleFileChange}
              />
              <span className="browse-files-text">browse file</span>
              <span>from device</span>
            </span>
          </label>
        </div>
        {cannotUpload && (
          <span className="cannot-upload-message">
            <span className="material-icons-outlined">error</span>
            Please select a file first
            <span
              className="material-icons-outlined cancel-alert-button"
              onClick={handleCancelAlert}
            >
              cancel
            </span>
          </span>
        )}
        <div className="file-block" ref={uploadedFileRef} style={{ display: "none" }}>
          <div className="file-info">
            <span className="material-icons-outlined file-icon">description</span>
            <span className="file-name">{fileName}</span> |{" "}
            <span className="file-size">{fileSize}</span>
          </div>
          <span
            className="material-icons remove-file-icon ms-4"
            onClick={handleRemoveFile}
          >
            delete
          </span>
          <div className="progress-bar" style={{ width: `${progressWidth}px` }}></div>
        </div>
        <button type="button" className="upload-button" onClick={handleUploadClick}>
          Upload
        </button>
      </div>
    </form>

      </Modal.Body>
     
    </Modal>
  );
}

export default UploadProfile;
