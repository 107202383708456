import React, { useState } from 'react';
import 'boxicons/css/boxicons.min.css';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";

const Sidebar = () => {
  const [showSidebar, setShowSidebar] = useState(true);
  const [activeLink, setActiveLink] = useState('dashboard');
  const navigate = useNavigate();

  const handleToggleClick = () => {
    setShowSidebar(!showSidebar);
  };

  const handleLinkClick = (linkName) => {
    setActiveLink(linkName);
  };

  const handleClick = (event) => {
    event.preventDefault();
    Cookies.remove('userId');
    Cookies.remove('member_detail');
    toast.success("Logout successful!");
    navigate("/");
  };

  return (
    <>
      <div id="sidebar" className={showSidebar ? '' : ''}>
        <div className={`l-navbar ${showSidebar ? 'show1' : ''}`}>
          <nav className="nav">
            <div className="nav_list">
              <div className="nav_logo">
                <img src={`${process.env.PUBLIC_URL}/FL-select-logo2.png`} alt="Logo" className="nav_logo-icon" style={{ width: "11%", marginLeft: "-14px" }} />
                <span className="nav_logo-name">Your Logo</span>
              </div>

           
              <a
                href="#"
                className={`nav_link toggle-btn ${showSidebar ? 'bx bx-layer nav_logo-icon-x-x' : 'bx-menu'}`}
                onClick={handleToggleClick}
              >
                <i className={`bx ${showSidebar ? '' : 'bx-menu'} nav_icon`}></i>
                <span className="nav_name"></span>
              </a>

              <Link to="/"
                className={`nav_link ${activeLink === 'dashboard' ? 'active' : ''}`}
                onClick={() => handleLinkClick('dashboard')}
              >
                <i className='bx bx-grid-alt nav_icon'></i>
                <span className="nav_name">Dashboard</span>
              </Link>

              <Link to="/feeds"
                className={`nav_link ${activeLink === 'Feeds' ? 'active' : ''}`}
                onClick={() => handleLinkClick('Feeds')}
              > 
              <i className='bx bx-bar-chart-alt-2 nav_icon'></i>
                <span className="nav_name">Global Time line Posts</span>
              </Link>

             

              <Link to="/membership"
                href="#"
                className={`nav_link ${activeLink === 'Membership' ? 'active' : ''}`}
                onClick={() => handleLinkClick('Membership')}
              >
                <i className='bx bx-bookmark nav_icon'></i>
                <span className="nav_name">Membership</span>
              </Link>


              <Link to="/Profile"
                href="#"
                className={`nav_link ${activeLink === 'Profile' ? 'active' : ''}`}
                onClick={() => handleLinkClick('Profile')}
              >
                <i className='bx bx-user nav_icon'></i>
                <span className="nav_name">Profile</span>
              </Link>


              <Link
                href="#"
                className={`nav_link ${activeLink === 'signout' ? 'active' : ''}`}
                onClick={handleClick}
              >
                <i className='bx bx-log-out nav_icon'></i>
                <span className="nav_name">SignOut</span>
              </Link>

            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
